import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'src/app/shared/shared.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ERToolRoutingModule } from './er-tool-routing.module';
import { ERToolComponent } from './components/er-tool.component';
import { ErListComponent } from './components/er-list/er-list.component';
import { ImportWizardFileDownloadComponent } from './components/import-wizard-file-download/import-wizard-file-download.component';

@NgModule({
  declarations: [    
   ERToolComponent,
   ErListComponent,
   ImportWizardFileDownloadComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    ScrollingModule,
    CdkTableModule,
    MatSortModule,
    MatTableModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    ERToolRoutingModule
  ],
})
export class ERToolModule {}
