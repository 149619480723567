<div class="inner-mat-dialog title-section flex justify-between content-stretch items-stretch flex-col">
  <div mat-dialog-title id="drug-details-header">
    <div class="flex justify-start content-center items-center flex-row">

      <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
        <h3>Add New Drug Wizard</h3>
      </div>
      <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
        <mat-icon (click)="this.dialogRef.close()" class="cross-wizard">
          <img src="/assets/img/icons/ico.16.clear.svg" alt>
        </mat-icon>
      </div>
    </div>
  </div>
  <form [formGroup]="saveDrugForm" style="overflow: scroll;">
    <div style="padding: 1rem;  overflow: scroll;" class="flex flex-col">
      <div class="flex flex-row gap-[23px]">
        <mat-card>
          <mat-card-title class="flex justify-center content-stretch items-stretch">Front Image </mat-card-title>
          <mat-card-content class="flex justify-center content-stretch items-stretch">
            <div class="file-upload">
              <div class="selected-file">
                <img [src]="getBase64ImageSrc(frontImage)??defaultImage" alt="drug image">
              </div>
              <div *ngIf="frontImage!= null" style="text-align: center;">
                <button class="btn btn-active" (click)="selectedFrontFileRemove()">
                  <mat-icon>clear</mat-icon>Remove</button>
              </div>
              <div class="choose_file_syn" *ngIf="frontImage == null">
                <label for="choose_file_syn">
                  <input type="file" id="choose_file_syn" accept="image/png, image/gif, image/jpeg"
                    (change)="onFileSelected($event, 1)">
                  <span>SELECT ADD IMAGE</span>
                </label>

              </div>

            </div>

          </mat-card-content>

        </mat-card>
        <mat-card>
          <mat-card-title class="flex justify-center content-stretch items-stretch">Back Image </mat-card-title>
          <mat-card-content class="flex justify-center content-stretch items-stretch">
            <div class="file-upload">
              <div class="selected-file">
                <img [src]="getBase64ImageSrc(backImage)??defaultImage" alt="drug image">
              </div>
              <div *ngIf="backImage!= null" style="text-align: center;">
                <button class="btn btn-active" (click)="selectedBackFileRemove()">
                  <mat-icon>clear</mat-icon>Remove</button>
              </div>
              <div class="choose_file_back" *ngIf="backImage == null">
                <label for="choose_file_back">
                  <input type="file" id="choose_file_back" accept="image/png, image/gif, image/jpeg"
                    (change)="onFileSelected($event, 2)">
                  <span>SELECT ADD IMAGE</span>
                </label>

              </div>

            </div>

          </mat-card-content>

        </mat-card>
        <mat-card>
          <mat-card-title class="flex justify-center content-stretch items-stretch">Side Image </mat-card-title>
          <mat-card-content class="flex justify-center content-stretch items-stretch">
            <div class="file-upload">
              <div class="selected-file">
                <img [src]="getBase64ImageSrc(sideImage)??defaultImage" alt="drug image">
              </div>
              <div *ngIf="sideImage!= null" style="text-align: center;">
                <button class="btn btn-active" (click)="selectedSideFileRemove()">
                  <mat-icon>clear</mat-icon>Remove</button>
              </div>
              <div class="choose_file_side" *ngIf="sideImage == null">
                <label for="choose_file_side">
                  <input type="file" id="choose_file_side" accept="image/png, image/gif, image/jpeg"
                    (change)="onFileSelected($event,3)">
                  <span>SELECT ADD IMAGE</span>
                </label>

              </div>

            </div>

          </mat-card-content>

        </mat-card>
        <mat-card>
          <mat-card-title class="flex justify-center content-stretch items-stretch">Top Image </mat-card-title>
          <mat-card-content class="flex justify-center content-stretch items-stretch">
            <div class="file-upload">
              <div class="selected-file">
                <img [src]="getBase64ImageSrc(topImage)??defaultImage" alt="drug image">
              </div>
              <div *ngIf="topImage!= null" style="text-align: center;">
                <button class="btn btn-active" (click)="selectedTopFileRemove()">
                  <mat-icon>clear</mat-icon>Remove</button>
              </div>
              <div class="choose_file_top" *ngIf="topImage == null">
                <label for="choose_file_top">
                  <input type="file" id="choose_file_top" accept="image/png, image/gif, image/jpeg"
                    (change)="onFileSelected($event,4)">
                  <span>SELECT ADD IMAGE</span>
                </label>

              </div>

            </div>

          </mat-card-content>

        </mat-card>
      </div>
      <div class="drug-left-side">
        <h5>Characteristics</h5>
        <div class="row flex gap-[1rem]" style="margin-bottom: .8rem;">

          <div class="flex flex-col w-[46%]">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Drug Name</span>
            </div>

            <!-- Drug Name (list Drug Name data) Autocomplete Field -->
            <mat-form-field class="full-width">
              <input matInput [formControl]="drugName" [matAutocomplete]="autoDrugName" id="drugNameInput">
              <mat-autocomplete #autoDrugName="matAutocomplete" [displayWith]="displayFnDrugName.bind(this)">
                <mat-option *ngFor="let drug of filteredDrugNames" [value]="drug">
                  {{ drug.label }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <div *ngIf="drugName.hasError('required') && drugName.touched">
              <mat-error>Drug Name is required</mat-error>
            </div>
          </div>

          <!-- Generic Name Indicator -->
          <div class="flex flex-col basis-1/5">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Brand / Generic</span>
            </div>
            <mat-form-field class="full-width">
              <mat-select formControlName="genericNameIndicator" ngDefaultControl>
                <mat-option value="1">Generic</mat-option>
                <mat-option value="2">Brand</mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="saveDrugForm.controls.genericNameIndicator.hasError('required') && saveDrugForm.controls.genericNameIndicator.touched">
              <mat-error *ngIf="saveDrugForm.controls.genericNameIndicator.hasError('required')">Generic Name Indicator
                is required</mat-error>
            </div>
          </div>

          <!-- Drug Number Type -->
          <div class="flex flex-col basis-1/5">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Drug Number Type</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="drugClassificationType" [(ngModel)]="data"
                (selectionChange)="updateDrugNumberFormat()">
                <mat-option *ngFor="let data of listOfDrugClassificationType"
                  [value]="data.value">{{data?.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="saveDrugForm.controls.drugClassificationType.hasError('required') && saveDrugForm.controls.drugClassificationType.touched">
              <mat-error *ngIf="saveDrugForm.controls.drugClassificationType.hasError('required')">Drug Number
                type
                is required</mat-error>
            </div>
          </div>

          <!-- Drug Number Format -->
          <div class="flex flex-col basis-1/5">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Drug Number Format</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="drugNumberFormat">
                <mat-option *ngFor="let data of filteredDrugNumberFormat"
                  [value]="data.value">{{data?.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="saveDrugForm.controls.drugNumberFormat.hasError('required') && saveDrugForm.controls.drugNumberFormat.touched">
              <mat-error *ngIf="saveDrugForm.controls.drugNumberFormat.hasError('required')">Drug Number Format is
                required</mat-error>
            </div>
          </div>


          <div class="flex flex-col basis-1/5">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Drug Number</span>
            </div>
            <input formControlName="drugId" type="text">

            <div *ngIf="saveDrugForm.controls.drugId.touched">
              <mat-error *ngIf="saveDrugForm.controls.drugId.hasError('required')">Drug Number is required</mat-error>
              <mat-error *ngIf="saveDrugForm.get('drugId')?.hasError('minlength')">Drug Number must be of {{ saveDrugForm.get('drugId')?.errors?.minlength?.requiredLength }} digits.</mat-error>
              <mat-error *ngIf="saveDrugForm.get('drugId')?.hasError('maxlength')">Drug Number must be of {{ saveDrugForm.get('drugId')?.errors?.maxlength?.requiredLength }} digits.</mat-error>
              <mat-error *ngIf="saveDrugForm.controls.drugId.hasError('drugIdExists')">Drug Number Already Exists</mat-error>
            </div>
          </div>
          <div class="flex flex-col basis-1/5">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Country</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="country" ngDefaultControl>
                <mat-option *ngFor="let data of countryList" [value]="data.value">{{data?.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="saveDrugForm.controls.country.hasError('required') && saveDrugForm.controls.country.touched">
              <mat-error *ngIf="saveDrugForm.controls.country.hasError('required')">Country
                is required</mat-error>
            </div>
          </div>

        </div>
        <div class="row flex gap-[1rem]" style="margin-bottom: .8rem;">
          <div class="flex flex-col w-[46%]">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Manufacturer</span>
            </div>
            <mat-form-field>
              <input type="text" aria-label="Number" matInput [formControl]="manufacturer"
                [matAutocomplete]="autoManufacturer">

              <mat-autocomplete #autoManufacturer="matAutocomplete" [displayWith]="displayFnManufacturer.bind(this)">
                <mat-option *ngFor="let data of listofManufacture" [value]="data.value">
                  {{data.label}}
                </mat-option>
                <mat-option *ngIf="isLoading" class="is-loading">
                  <mat-spinner diameter="25"></mat-spinner>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div
              *ngIf="saveDrugForm.controls.manufacturer.hasError('required') && saveDrugForm.controls.manufacturer.touched">
              <mat-error *ngIf="saveDrugForm.controls.manufacturer.hasError('required')">Manufacturer
                is required</mat-error>
            </div>
          </div>

          <!-- Route of Administration -->
          <div class="flex flex-col basis-1/5">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Route Of Administration</span>
            </div>
            <mat-form-field>
              <input type="text" aria-label="Number" matInput [formControl]="routeOfAdministration"
                [matAutocomplete]="autoRoutOfAdministration">
              <mat-autocomplete #autoRoutOfAdministration="matAutocomplete"
                [displayWith]="displayFnRoutOfAddministration.bind(this)">
                <mat-option *ngFor="let data of filteredRouteOfAdministration" [value]="data.value">
                  {{data.label}}
                </mat-option>
                <mat-option *ngIf="isLoading" class="is-loading">
                  <mat-spinner diameter="25"></mat-spinner>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div *ngIf="saveDrugForm.controls.routeOfAdministration.touched">
              <mat-error *ngIf="saveDrugForm.controls.routeOfAdministration.hasError('required')">Route Of
                Administration is required</mat-error>
              <mat-error *ngIf="saveDrugForm.controls.routeOfAdministration.hasError('invalidRouteOfAdmin')">Please
                enter a valid option</mat-error>
            </div>
          </div>

          <!-- Dosage Form Autocomplete -->
          <div class="flex flex-col basis-1/5">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Dosage Form</span>
            </div>
            <mat-form-field>
              <input type="text" aria-label="Number" matInput [formControl]="dosageForm"
                [matAutocomplete]="autodosageForm">
              <mat-autocomplete #autodosageForm="matAutocomplete" [displayWith]="displayFnDosageForm.bind(this)">
                <mat-option *ngFor="let data of filteredDosageForm" [value]="data.id">
                  {{data.description}}
                </mat-option>
                <mat-option *ngIf="isLoading" class="is-loading">
                  <mat-spinner diameter="25"></mat-spinner>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div *ngIf="saveDrugForm.controls.dosageForm.touched">
              <mat-error *ngIf="saveDrugForm.controls.dosageForm.hasError('required')">Dosage Form is
                required</mat-error>
              <mat-error *ngIf="saveDrugForm.controls.dosageForm.hasError('invalidDosageForm')">Please enter a valid
                option</mat-error>
            </div>
          </div>

          <div class="flex flex-col basis-1/5">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Schedule</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="schedule" ngDefaultControl>
                <mat-option *ngFor="let data of listOfSchedule" [value]="data.key">{{data?.value}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="saveDrugForm.controls.schedule.hasError('required') && saveDrugForm.controls.schedule.touched">
              <mat-error *ngIf="saveDrugForm.controls.schedule.hasError('required')">Schedule
                is required</mat-error>
            </div>
          </div>
          <div class="flex flex-col basis-1/5">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Strength</span>
            </div>
            <input formControlName="strength" type="text">
            <div *ngIf="saveDrugForm.controls.strength.touched">
              <mat-error *ngIf="saveDrugForm.controls.strength.errors?.required">Strength is required</mat-error>
              <mat-error
                *ngIf="!saveDrugForm.controls.strength.errors?.required && saveDrugForm.controls.strength.errors?.positiveRealNumber">
                Must be 1-9, decimal or combo (e.g., 1 / 2). No other symbols.
              </mat-error>
            </div>
          </div>
          <div class="flex flex-col basis-1/5">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">STR Unit</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="unit" ngDefaultControl>
                <mat-option *ngFor="let data of listofUnit" [value]="data.value">{{data?.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="saveDrugForm.controls.unit.touched">
              <mat-error *ngIf="saveDrugForm.controls.unit.hasError('required')">Unit is required</mat-error>
              <mat-error
                *ngIf="!saveDrugForm.controls.unit.errors?.required && saveDrugForm.controls.unit.hasError('invalidUnit')">Invalid
                Unit selected. Please select from the list.</mat-error>
            </div>
          </div>


        </div>

        <div class="row flex gap-[1rem]" style="margin-bottom: .8rem;">
          <div class="flex flex-col basis-[30%]">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Storage Conditions</span>
            </div>
            <textarea formControlName="storageConditions" rows="7"></textarea>
            <div
              *ngIf="saveDrugForm.controls.storageConditions.hasError('required') && saveDrugForm.controls.storageConditions.touched">
              <mat-error *ngIf="saveDrugForm.controls.storageConditions.hasError('required')">Storage
                Condition is required</mat-error>
            </div>
          </div>

          <!-- Unit of Use Dropdown -->
          <div class="basis-[70%]">
            <div style="margin-bottom: .8rem;" class="flex flex-row gap-[1rem]">
              <div class="flex flex-col basis-1/5">
                <div class="flex flex-row">
                  <span class="text-red-500">*</span>
                  <span class="label">Unit of Use &nbsp;</span>
                  <span class="help_icon" matTooltip=" The unit-of-use container as one that contains a specific quantity of a drug product that is intended to be dispensed as such without further modification except for the addition of appropriate labeling." matTooltipPosition="after" matTooltipClass="custom_tooltip">
                    <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
                  </span>
                </div>
                <mat-form-field>
                  <mat-select formControlName="unitOfUse" ngDefaultControl>
                    <mat-option value="1">Yes</mat-option>
                    <mat-option value="0">No</mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="saveDrugForm.controls.unitOfUse.hasError('required') && saveDrugForm.controls.unitOfUse.touched">
                  <mat-error *ngIf="saveDrugForm.controls.unitOfUse.hasError('required')">Unit of use is
                    required</mat-error>
                </div>
              </div>

              <!-- Unit of Use Dropdown -->
              <div class="flex flex-col basis-1/5">
                <div class="flex flex-row">
                  <span class="text-red-500">*</span>
                  <span class="label">Unit Dose &nbsp;</span>
                  <span class="help_icon" matTooltip=" The unit dose refers to a method of dispensing medications where each dose of a drug is individually packaged and labeled for a single administration to a patient." matTooltipPosition="after" matTooltipClass="custom_tooltip">
                    <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
                  </span>
                </div>
                <mat-form-field>
                  <mat-select formControlName="unitDose" ngDefaultControl>
                    <mat-option value="1">Yes</mat-option>
                    <mat-option value="0">No</mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="saveDrugForm.controls.unitDose.hasError('required') && saveDrugForm.controls.unitDose.touched">
                  <mat-error *ngIf="saveDrugForm.controls.unitDose.hasError('required')">Unit
                    dose is required</mat-error>
                </div>
              </div>
              <div class="flex flex-col basis-1/5">
                <div class="flex flex-row">
                  <span class="label">Package Qty</span>
                </div>
                <input type="text" aria-label="Number" matInput [formControl]="packageSize">
              </div>

              <div class="flex flex-col basis-1/5">
                <div class="flex flex-row">
                  <span class="text-red-500">*</span>
                  <span class="label">Package Type</span>
                </div>
                <mat-form-field>
                  <mat-select formControlName="packageType" ngDefaultControl>
                    <mat-option *ngFor="let data of listOfPackageType" [value]="data.value">{{data.label}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="saveDrugForm.controls.packageType.hasError('required') && saveDrugForm.controls.packageType.touched">
                  <mat-error *ngIf="saveDrugForm.controls.packageType.hasError('required')">Package
                    type is required</mat-error>
                </div>
              </div>

              <div class="flex flex-col basis-1/5">
                <div class="flex flex-row">
                  <span class="label">Coating</span>
                </div>
                <mat-form-field>
                  <mat-select formControlName="coating" ngDefaultControl>
                    <mat-option *ngFor="let data of listOfCoating" [value]="data.key">{{data?.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            </div>
            <div style="margin-bottom: .8rem;" class="flex flex-row gap-[1rem]">
              <div class="flex flex-col basis-1/5">
                <div class="flex flex-row">
                  <span class="text-red-500">*</span>
                  <span class="label">30 Dram Capacity</span>
                </div>
                <input formControlName="thirtyDramCapacity" type="number" />
                <div
                  *ngIf="saveDrugForm.controls.thirtyDramCapacity.hasError('required') && saveDrugForm.controls.thirtyDramCapacity.touched">
                  <mat-error *ngIf="saveDrugForm.controls.thirtyDramCapacity.hasError('required')">Thirty
                    Dram Capacity is required</mat-error>
                </div>

              </div>

              <div class="flex flex-col basis-1/5">
                <div class="flex flex-row">
                  <span class="label">Imprint 1</span>
                </div>
                <input formControlName="imprint1" type="text" />
              </div>
              <div class="flex flex-col basis-1/5">
                <div class="flex flex-row">
                  <span class="label">Imprint 2</span>
                </div>
                <input formControlName="imprint2" type="text" />
              </div>
              <div class="flex flex-col basis-1/5">
                <div class="flex flex-row">
                  <span class="label">Scoring</span>
                </div>
                <mat-form-field>
                  <mat-select formControlName="scoring" ngDefaultControl>
                    <mat-option *ngFor="let data of listOfScoaring" [value]="data.key">{{data?.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="flex flex-col basis-1/5">
                <div class="flex flex-row">
                  <span class="text-red-500">*</span>
                  <span class="label">Color</span>
                </div>
                <mat-form-field appearance="legacy" class="chip-list">
                  <mat-chip-list #chipList aria-label="Color selection">
                    <mat-chip *ngFor="let color of selectedColors" (removed)="remove(color)"
                      [ngStyle]="{'margin-top': '10px', 'margin-left': '10px'}">
                      {{color}}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip>
                    <input #colorInput [formControl]="color"
                      [matAutocomplete]="autoColor" [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
                  </mat-chip-list>
                  <mat-autocomplete #autoColor="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let color of filteredColors | async" [value]="color">
                      {{color}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>

                <div
                  *ngIf="saveDrugForm.controls.color.hasError('colorRequired') && saveDrugForm.controls.color.touched">
                  <mat-error *ngIf="saveDrugForm.controls.color.hasError('colorRequired')">Color is required</mat-error>
                </div>

                <!-- Show "You can only select a maximum of 2 colors" error -->
                <div *ngIf="saveDrugForm.controls.color.hasError('maxColors') && saveDrugForm.controls.color.touched">
                  <mat-error *ngIf="saveDrugForm.controls.color.hasError('maxColors')">You can only select a maximum of
                    2 colors</mat-error>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row flex gap-[1rem]" style="margin-bottom: .8rem;">
          <div class="flex flex-col w-[30%]">

            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Barcode</span>
            </div>
            <input formControlName="barcode" type="text">
            <div *ngIf="saveDrugForm.controls.barcode.touched">
              <mat-error *ngIf="saveDrugForm.controls.barcode.hasError('required')">Barcode
                is required</mat-error>
              <mat-error *ngIf="saveDrugForm.controls.barcode.hasError('minlength')">Barcode must be at least 10
                digits</mat-error>
              <mat-error *ngIf="saveDrugForm.controls.barcode.hasError('maxlength')">Barcode cannot exceed 12
                digits</mat-error>
              <mat-error *ngIf="saveDrugForm.controls.barcode.hasError('pattern')">Barcode must be between 10-12
                digits</mat-error>
            </div>
          </div>



          <div class="flex flex-col w-[13%]" style="margin-top: 3px;">
            <div class="flex flex-row">
              <span class="label">Niosh</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="niosh" ngDefaultControl>
                <mat-option *ngFor="let data of listOfNiosh" [value]="data.value">{{data?.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div class="flex flex-col w-[13%]" style="margin-top: 3px;">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">AntiBiotic</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="antibiotic" ngDefaultControl>
                <mat-option [value]="true">YES</mat-option>
                <mat-option [value]="false">NO</mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="saveDrugForm.controls.antibiotic.hasError('required') && saveDrugForm.controls.antibiotic.touched">
              <mat-error *ngIf="saveDrugForm.controls.antibiotic.hasError('required')">Antibiotic
                is required</mat-error>
            </div>
          </div>

          <div class="flex flex-col w-[13%]" style="margin-top: 3px;">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Pill Shape</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="pillShape" ngDefaultControl>
                <mat-option *ngFor="let data of pillShapeList" [value]="data.value">{{data?.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="saveDrugForm.controls.pillShape.hasError('required') && saveDrugForm.controls.pillShape.touched">
              <mat-error *ngIf="saveDrugForm.controls.pillShape.hasError('required')">Pill Shape
                is required</mat-error>
            </div>
          </div>

          <!-- Drug Status -->
          <div class="flex flex-col w-[13%]" style="margin-top: 3px;">
            <div class="flex flex-row">
              <span class="text-red-500">*</span>
              <span class="label">Drug Status</span>
            </div>
            <mat-form-field>
              <mat-select formControlName="drugStatus" ngDefaultControl>
                <mat-option *ngFor="let data of listOfDrugStatus" [value]="data.value">{{data?.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="saveDrugForm.controls.drugStatus.hasError('required') && saveDrugForm.controls.drugStatus.touched">
              <mat-error *ngIf="saveDrugForm.controls.drugStatus.hasError('required')">Drug Status is
                required</mat-error>
            </div>
          </div>

        </div>

        <h5>Attributes</h5>
          <div class="row flex gap-[1rem]" style="margin-bottom: .8rem;">
            <div class="flex flex-col basis-[30%]">
              <span class="label">Length (inches)</span>
              <input formControlName="length" type="number">
            </div>
            <div class="flex flex-col basis-[10%]">
              <span class="label">Width (inches)</span>
              <input formControlName="width" type="number">
            </div>
            <div class="flex flex-col basis-[10%]">
              <span class="label">Thickness (inches)</span>
              <input formControlName="thickness" type="number">
            </div>
            <div class="flex flex-col basis-[15%]">
              <span class="label">Piece Weight (grams)</span>
              <input formControlName="pieceWeight" type="number">
            </div>
            <div class="flex flex-col basis-[15%]">
              <span class="label">Diagonal</span>
              <input formControlName="diagonal" type="number">
            </div>
          </div>
          <div class="row flex gap-[1rem]" style="margin-bottom: .8rem;">
            <div class="flex flex-col basis-[30%]">
              <span class="label">Classification Shape</span>
              <mat-form-field>
                <mat-select formControlName="classificationShape" ngDefaultControl>
                  <mat-option *ngFor="let data of listOfClassificationShape"
                    [value]="data.value">{{data?.label}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex flex-col basis-[21%]">
              <span class="label">Qty Classified</span>
              <input formControlName="qtyClassified" type="text">
            </div>
            <div class="flex flex-col basis-[10%]">
              <span class="label">Qty Weighted</span>
              <input formControlName="qtyWeighted" type="text">
            </div>
          </div>

        <div class="row flex gap-[1rem]" style="margin-bottom: .8rem;">
          <div class="flex flex-col basis-[30%]">
            <span class="label">Classification Shape</span>

            <mat-form-field>
              <mat-select formControlName="classificationShape" ngDefaultControl>
                <mat-option *ngFor="let data of listOfClassificationShape"
                  [value]="data.value">{{data?.label}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flex flex-col basis-[21%]">
            <span class="label">Qty Classified</span>
            <input formControlName="qtyClassified" type="text">
          </div>
          <div class="flex flex-col basis-[10%]">
            <span class="label">Qty Weighted</span>
            <input formControlName="qtyWeighted" type="text">
          </div>
        </div>
      </div>

    </div>
  </form>

  <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full xs:flex xs:flex-col">
    <button mat-button class="btn-blue-default btn-md" (click)="dialogRef.close()" id="CancelNewUser"
      aria-label="Cancel" type="button">Cancel</button>
    <button mat-button aria-label="Save Drug"
      [ngClass]="{'btn-primary':saveDrugForm?.valid, 'btn-disabled':!saveDrugForm?.valid}"
      [disabled]="!saveDrugForm?.valid" id="Next" (click)="save()" class="btn-md" type="button">Save Drug </button>
  </mat-dialog-actions>

</div>
