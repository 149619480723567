<div class="inner-mat-dialog flex justify-between content-stretch items-stretch flex-col">
  <form [formGroup]="systemConfigForm">
    <div>
      <h1 mat-dialog-title tabindex="0" class="xs:flex-1 sm:basis-full">
        System Configuration - {{systemConfigForm.value.edgeDeviceId}}
      </h1>
    </div>

    <mat-dialog-content>
      <div class="xs:flex xs:flex-col flex gap-4 flex-row h-20">
        <app-organization-select class="xs:flex-1 gt-xs:basis-1/4 pt-2" [form]="systemConfigForm"
          [orgControlName]="'organizationId'"
          [disabled]="provisioned || (systems.value.length > 0)"></app-organization-select>
        <mat-form-field floatLabel="never" class="xs:flex-1 gt-xs:basis-1/4 pt-2">
          <label>
            Device Friendly Name
            <custom-tooltip text="Human-readable name to help identify the device"></custom-tooltip>
          </label>
          <input type="text" matInput appNoHtmlTags trim [formControlName]="'edgeDeviceFriendlyName'">
        </mat-form-field>
        <div class="bg-gray-100 pl-4 flex gap-4 xs:flex-1 gt-xs:basis-3/4 h-full *:pt-2">
          <mat-form-field floatLabel="never" class="xs:flex-1 sm:basis-1/5 gt-sm:basis-[30%]">
            <label>
              Product Line Type
              <custom-tooltip text="Model of Device"></custom-tooltip>
            </label>
            <mat-select class="deploymentType" [(value)]="selectedDeploymentType" tabindex="0">
              <mat-option *ngFor="let type of getFormMetadata" [value]="type.name">
                {{ type.friendlyName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-button class="btn-default btn-sm self-center !mt-[22px]" tabindex="0" type="button"
            (click)="onAddSystemClick(selectedDeploymentType, $event)"
            [disabled]="!selectedDeploymentType || !organizationId">Add</button>
        </div>
      </div>

      <div>
        <h6>Edge Device Settings</h6>
        <span *ngIf="!deploymentLevelPropertiesShown">N/A</span>
        <div class="grid grid-cols-5 gap-4">
          <ng-container *ngFor="let key of orderedKeys">
            <app-dynamic-deployment-control 
              [hidden]="!showDeploymentLevelProperty(key)" 
              [form]="deploymentLevelProperties"
              [organizationId]="organizationId" 
              [controlMetadata]="getDeploymentLevelPropertyMap.get(key).propMetadata"
              required="false">
            </app-dynamic-deployment-control>
          </ng-container>
        </div>
      </div>

      <div>
        <h6>Product Features</h6>
        <span *ngIf="!systems.value.length">N/A</span>
        <table *ngIf="systems.value.length" mat-table>
          <ng-container matColumnDef="systemName">
            <th mat-header-cell *matHeaderCellDef class="w-[18%]">
              <div class="flex items-center">
                <img [ngStyle]="{'transform': accordionCollapsed ? 'rotate(180deg)' : 'rotate(0deg)'}"
                  (click)="toggleAccordion()" src="../../../../../assets/img/icons/ico.12.states.svg">
                <span class="pl-1">System Name</span>
              </div>
            </th>
          </ng-container>
          <ng-container matColumnDef="site">
            <th mat-header-cell *matHeaderCellDef class="w-[18%]">Site</th>
          </ng-container>
          <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef class="w-[18%] ">Serial Number</th>
          </ng-container>
          <ng-container matColumnDef="optionalFeatures">
            <th mat-header-cell *matHeaderCellDef class="w-[18%] ">Optional Features</th>
          </ng-container>
          <ng-container matColumnDef="placeholder">
            <th mat-header-cell *matHeaderCellDef class="w-[28%]"></th>
          </ng-container>
          <tr mat-header-row
            *matHeaderRowDef="['systemName', 'site', 'serialNumber', 'optionalFeatures', 'placeholder']"></tr>
        </table>
        <mat-accordion multi>
          <ng-container *ngFor="let systemType of distinctSystemTypes">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>{{ systemType }} ({{ getSystemTypeCount(systemType) }})</span>
                  <button mat-button class="btn-default btn-sm" tabindex="0" type="button"
                    (click)="onAddSystemClick(systemType, $event)">Add</button>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container formArrayName="systems" *ngFor="let system of systems.controls; let systemIndex = index">
                <!-- Group systems by type in each expansion panel -->
                <div class="flex border-[0] border-b border-solid border-gray-400" [formGroupName]="systemIndex" *ngIf="system.value.name === systemType">
                  <div *ngIf="!system.value.isEditMode" class="grid grid-cols-5 items-center basis-[90%] text-sm">
                    <span class="pl-10">{{system.value.systemFriendlyName || 'N/A'}}</span>
                    <span>{{system.value.siteId || 'N/A'}}</span>
                    <span>{{system.value.systemId || 'N/A'}}</span>
                    <span>{{getFeatureList(system.value.name, system.value.optionalFeatures)}}</span>
                  </div>
                  <div *ngIf="system.value.isEditMode" class="flex basis-[90%]">
                    <app-system-form class="w-full" [form]="system" [organizationId]="organizationId"
                      [systemFormMetadata]="getSystemFormMetadata(systemType)" (featureSelected)="onFeatureSelect($event)"></app-system-form>
                  </div>
                  <div class="flex basis-[10%] justify-evenly grow-0 w-full">
                    <button *ngIf="system.value.isEditMode" mat-mini-fab
                      class="!bg-transparent !shadow-none hover:!bg-gray-200" (click)="validateAndSaveSystem(system)">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="M440-440v240h-80v-160H200v-80h240Zm160-320v160h160v80H520v-240h80Z"/></svg>
                    </button>
                    <button *ngIf="!system.value.isEditMode" mat-mini-fab
                      class="!bg-transparent !shadow-none hover:!bg-gray-200" (click)="editSystem(system)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-mini-fab class="!bg-transparent !shadow-none hover:!bg-gray-200"
                      (click)="deleteSystem(systemIndex)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              </ng-container>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions class="flex justify-end content-end items-end flex-row">
      <button mat-button class="btn-default btn-md" [mat-dialog-close]="true" tabindex="0" type="button">Cancel</button>
      <button mat-button type="submit" class="btn-primary btn-md" id="Done" tabindex="0"  (click)="submit()">
        Configure
      </button>
    </mat-dialog-actions>
  </form>
</div>