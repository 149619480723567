import { SearchDrugListComponent } from './../search-drug-list/search-drug-list.component';
import { ImportWizardAddNewDrugComponent } from './../import-wizard-add-new-drug/import-wizard-add-new-drug.component';
import { FileUploadComponent } from './../file-upload/file-upload.component';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DrugCategory } from '../../models/drug';
import { routePaths } from 'src/app/config/app-constants';
import { RpoToolService } from '../../services/rpo-tool.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as constants from 'src/app/config/app-constants';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { QuarantinedDrugListComponent } from '../quarantined-drug-list/quarantined-drug-list.component';
import { DropDown } from '../../models/dropdown';
import { DropdownValues } from '../../models/synmed-data';
@Component({
  selector: 'app-drug-list',
  templateUrl: './drug-list.component.html',
  styleUrls: ['./drug-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class DrugListComponent implements OnInit {

  searchText: string = '';
  dataSource = new MatTableDataSource<DrugCategory>();
  columnsToDisplay = ['name', 'ndc', 'countryCode', 'source', 'system', 'strength', 'manufacturer', 'priority', 'requestor', 'age', 'action'];
  expandedElement: DrugCategory | null;
  DRUGES: DrugCategory[] = [];
  listOfCountry: DropDown[] = [];
  listOfDataSourceFilter: DropDown[] = [];
  permanentQuarantine: DropDown[] = DropdownValues.CommonBooleanDropDownValues;
  selectedSearchCriteria: any;

  searchCriteria = [
    { value: 'drugName', label: 'Drug Name' },
    { value: 'drugNumber', label: 'Drug number' },
    { value: 'pdbid', label: 'PDDBID' },
    { value: 'tcgid', label: 'TCGID' },
    { value: 'genericNumber', label: 'Generic Number' }
  ];

  @ViewChild(SearchDrugListComponent) child: SearchDrugListComponent;
  @ViewChild(QuarantinedDrugListComponent) quarantinedChild: QuarantinedDrugListComponent;

  //#region Pagination properties
  totalRows: number = 0;
  currentPage: number = 0;
  pageSize: number = 5;
  pageSizeOptions: number[] = [5, 10, 20, 40, 50];
  //#endregion
  dataSourceId: number = 0;
  countryId: number = -1;
  statusId: number = 0;
  searchResultsCount: number = 0;
  QuarantineResultsCount: number = 0;
  permanentQuarantineValue: boolean = false;
  //#endregion
  constructor(
    private rpoService: RpoToolService,
    private dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    private route: Router) { }

  ngOnInit(): void {
    this.getDrugData();
    this.getCountry();
    this.getDataSourceFilter();
    this.selectedSearchCriteria = this.searchCriteria[0];
    this.onSearchCriteriaChange();
  }
  /**
    * Method to refresh both child components
    */
  refreshChildComponents() {
    if (this.child) {
      this.child.getDrugData(); // Refresh SearchDrugListComponent
    }

    if (this.quarantinedChild) {
      this.quarantinedChild.getDrugData(); // Refresh QuarantinedDrugListComponent
    }
  }
  onCriteriaSelect(criteria: any) {
    this.selectedSearchCriteria = criteria;
    this.onSearchCriteriaChange();
  }

  updateSearchWizard() {
    if (!this.searchText || this.searchText.trim() === '') {

      this.searchResultsCount=0
    }
    this.getDrugData();
    this.refreshChildComponents();
  }
  onSearchCriteriaChange(){
    this.getDrugData();
  }
  openImportWizardFileUpload() {
    const dialogRef = this.dialog.open(FileUploadComponent, {
      backdropClass: ['lgDialog'],
      disableClose: true,
    });
  }
  openImportWizardNewDrug() {

    const dialogRef = this.dialog.open(ImportWizardAddNewDrugComponent, {
      backdropClass: ['lgDialog'],
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.getDrugData();
        this.refreshChildComponents();
      }
    });

  }

  getDrugData() {
    // Ensure searchText is not empty
    if (!this.searchText || this.searchText.trim() === '') {
      this.searchResultsCount=0;
    }

    this.ngxLoader.start();
    this.rpoService.getDrugData(this.dataSourceId, this.searchText, this.permanentQuarantineValue, this.countryId).subscribe((result) => {
      this.ngxLoader.stop();
      this.dataSource = new MatTableDataSource(result.data);
    },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        this.toastService.openToast(
          error.error.message ?? error.message,
          constants.ToastPanelType.error,
          2
        );
      })
  }
  handleSearchCountUpdate(count: number) {
    this.searchResultsCount = count;
  }
  handleQuarantineCountUpdate(count: number) {
    this.QuarantineResultsCount = count;
  }

  getCountry() {
    this.ngxLoader.start();
    this.rpoService.getCountry().subscribe((result) => {
      this.ngxLoader.stop();
      this.listOfCountry = result.data;
    },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        this.toastService.openToast(
          error.message,
          constants.ToastPanelType.error,
          2
        );
      })
  }

  getDataSourceFilter() {
    this.ngxLoader.start();
    this.rpoService.getDataSourceFilter().subscribe((result) => {
      this.ngxLoader.stop();
      this.listOfDataSourceFilter = result.data;
    },
      (error: HttpErrorResponse) => {
        this.ngxLoader.stop();
        this.toastService.openToast(
          error.message,
          constants.ToastPanelType.error,
          2
        );
      })
  }

}




