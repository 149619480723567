
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ERToolService } from '../../services/er-tool.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import * as constants from 'src/app/config/app-constants';

@Component({
  selector: 'app-import-wizard-file-download',
  templateUrl: './import-wizard-file-download.component.html',
  styleUrls: ['./import-wizard-file-download.component.scss']
})
export class ImportWizardFileDownloadComponent implements OnInit {

   // Dropdown options
   sources = [

    { value: '1', label: 'FDB' },
    { value: '5', label: 'Medi-Span (WK)' },
  ];

  fileExports = [
    { value: '1', label: 'Max/Mini MDL' },
    { value: '2', label: 'ATP MDL' },
    { value: '3', label: 'Parata Connect MDL' },
    { value: '4', label: 'Max/Mini DrugImpageZip.EXE' },
  ];

  formatOptions = {
     '1': [
      { value: 'xml', label: 'XML' },
      { value: 'csv', label: 'CSV' },
    ],
     '2': [
      { value: 'csv', label: 'CSV' },
      { value: 'xlsx', label: 'XLSX' },
    ],
    '3': [{ value: 'xlsx', label: 'XLSX' }],
    '4': [{ value: 'exe', label: 'EXE' }],
  };

  formats: { value: string; label: string }[] = [];

  // Selected values
  selectedSource: string = '5';
  selectedExport: string | null = null;
  selectedFormat: string | null = null;

  constructor(
    private erToolService: ERToolService,
    public dialogRef: MatDialogRef<ImportWizardFileDownloadComponent>,
    public dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    private fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {


  }


  onSourceChange() {
    this.selectedExport = null;
    this.selectedFormat = null;
  }

  onExportChange() {
    // Update formats based on selected file export
    this.formats = this.formatOptions[this.selectedExport!] || [];
    this.selectedFormat = this.formats.length > 0 ? this.formats[0].value : null;
  }

  isFormValid(): boolean {
    return !!this.selectedSource && !!this.selectedExport && !!this.selectedFormat;
  }



  ngOnInit(): void {

  }



  onDownload() {
    this.getBlobUrl(this.selectedSource, this.selectedExport, this.selectedFormat );
  }


  getBlobUrl(selectedSourceId: any, selectedExport: any, selectedFormat: any) {

    this.erToolService.getMdlSasToken(selectedSourceId ,selectedExport, selectedFormat).subscribe(
      (response) => {

        let result = response.data;
        if (result != null)
        {
          if(result.sasToken != null ){
            this.downloadFile(result.sasToken, result.fileName);
            this.ngxLoader.stop();
            this.toastService.openToast("File successfully downloaded!", constants.ToastPanelType.done,
              1
            );
          }
          else{
            this.toastService.openToast(
              'No result Found', constants.ToastPanelType.error,   2
            );
          }

        }else {
          this.toastService.openToast(
            'No result Found', constants.ToastPanelType.error,   2
          );
        }

      },
      (error: HttpErrorResponse) => {

        this.toastService.openToast(
          error.error, constants.ToastPanelType.error,   2
        );
      }
    );

}

downloadFile(blobUrl: string, fileName: string): void {

  const url = blobUrl;
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}


}
