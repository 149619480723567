import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appDigitLengthValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DigitLengthValidatorDirective,
      multi: true
    }
  ]
})
export class DigitLengthValidatorDirective implements Validator {
  
  // Regular expression to match only digits (no spaces, dashes, or special characters)
  private digitRegex = /^\d*$/;

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if(value == '--'){
      return null;
    }
    // Return null if value is null or undefined (valid case)
    if (value === null || value === undefined) {
      return null;
    }

    // Check if value contains only digits
    if (!this.digitRegex.test(value)) {
      return { 'invalidCharacters': 'Only digits are allowed.' };
    }

    // Check for minimum and maximum length
    if (value.length < 10) {
      return { 'minLength': 'Input must be at least 10 digits long.' };
    } else if (value.length > 12) {
      return { 'maxLength': 'Input must not exceed 12 digits.' };
    }

    return null; // Valid input
  }
}
