import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { DrugCategory } from 'src/app/internal-user/drug-management/models/drug';

import { ImportWizardAddNewDrugComponent } from 'src/app/internal-user/drug-management/components/import-wizard-add-new-drug/import-wizard-add-new-drug.component';
import { ImportWizardFileDownloadComponent } from '../import-wizard-file-download/import-wizard-file-download.component';


@Component({
  selector: 'app-er-list',
  templateUrl: './er-list.component.html',
  styleUrls: ['./er-list.component.scss'],
  animations: [
    trigger('detailExpand', [
        state('collapsed', style({height: '0px', minHeight: '0'})),
        state('expanded', style({height: '*'})),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ErListComponent implements OnInit {

  searchText:string = '';
  dataSource= new MatTableDataSource<DrugCategory>();
  columnsToDisplay = ['name', 'ndc', 'countryCode','source','system', 'strength', 'manufacturer'];
  expandedElement: DrugCategory | null;


  //#region Pagination properties
  totalRows: number = 0;
  currentPage: number = 0;
  pageSize: number = 5;
  pageSizeOptions: number[] = [5, 10, 20, 40, 50];
  //#endregion
   dataSourceId:number= -1;
   countryId:number= -1;
   statusId:number=0;
   permanentQuarantineValue:boolean = false;
 //#endregion
  constructor(

    private dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    private route: Router) { }

  ngOnInit(): void {

  }




  openImportWizardFileDownloadComponent(){

    const dialogRef = this.dialog.open(ImportWizardFileDownloadComponent, {
      width: '40%', 
    disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
      }
    });

  }



}




