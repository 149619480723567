/** organization roles parata internal employees & pharmacy managers (client) */
export enum Role {
  implementationMgr = 'IM',
  pharmacy = 'PM',
  admin = 'AU',
  inventorySpecialist = 'InventorySpecialist',
  reportViewer = 'ReportViewer',
  ptac = 'PTAC',
  rpo = 'RPO',
  parataDev = 'Parata Dev',
  orgAdmin = 'OrgAdmin',
  ert = 'CDDB Viewer'
}
