import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appNoLeadingSpace]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NoLeadingSpaceDirective,
      multi: true
    }
  ]
})
export class NoLeadingSpaceDirective implements Validator {

  // Regular expression to check for leading spaces
  private leadingSpaceRegex = /^\s+/;

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    // Return null if value is null or undefined (valid case)
    if (value === null || value === undefined) {
      return null;
    }

    // Check if value starts with a space
    if (this.leadingSpaceRegex.test(value)) {
      return { 'leadingSpace': 'Input should not start with a space.' };
    }

    return null; // Valid input
  }
}
