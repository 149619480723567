<div class="mat-elevation-z8 table-height">
  <table  mat-table [dataSource]="dataSource">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef width ="30%"> Name </th>
          <td mat-cell *matCellDef="let element" width ="30%"> {{element?.name}} </td>
      </ng-container>

      <!-- NDC Column -->
      <ng-container matColumnDef="ndc">
          <th mat-header-cell *matHeaderCellDef width ="10%"> NDC </th>
          <td mat-cell *matCellDef="let element" width ="10%"> {{element?.ndc}} </td>
      </ng-container>

         <!-- Country Column -->
     <ng-container matColumnDef="countryCode">
        <th mat-header-cell *matHeaderCellDef width ="10%"> Country </th>
        <td mat-cell *matCellDef="let element" width ="10%">      
            <p style="display: inline-block; vertical-align: middle;">
                <img *ngIf="element?.countryCode?.length>0"  [src]="element?.countryCode === 'USA' ? '/assets/img/country/usa.png': element?.countryCode === 'CAN'? '/assets/img/country/canadian.webp':'/assets/img/no-image.png'" style="height: 25px; width: 25px; vertical-align: middle;" />
                  {{element?.countryCode}}
            </p>
          </td>
    </ng-container>
  
    <!-- Data Source Column -->
    <ng-container matColumnDef="source">
        <th mat-header-cell *matHeaderCellDef width ="10%"> Source </th>
        <td mat-cell *matCellDef="let element" width ="10%"> {{element?.source}} </td>
    </ng-container>

      <!-- System Column -->
      <ng-container matColumnDef="system">
          <th mat-header-cell *matHeaderCellDef width ="10%"> System </th>
          <td mat-cell *matCellDef="let element" width ="10%"> {{element?.system}} </td>
      </ng-container>

      <!-- Strength Column -->
      <ng-container matColumnDef="strength">
          <th mat-header-cell *matHeaderCellDef width ="10%"> Strength </th>
          <td mat-cell *matCellDef="let element" width ="10%"> {{element?.strength}} </td>
      </ng-container>


      <!-- Manufacturer Column -->
      <ng-container matColumnDef="manufacturer">
          <th mat-header-cell *matHeaderCellDef width ="10%"> Manufacturer</th>
          <td mat-cell *matCellDef="let element" width ="10%"> {{element?.manufacturer}} </td>
      </ng-container>
       <!-- Priority Column -->
       <ng-container matColumnDef="priority">
          <th mat-header-cell *matHeaderCellDef width ="10%"> Priority</th>
          <td mat-cell *matCellDef="let element" width ="10%"> {{element?.priority}} </td>
      </ng-container>

      <!-- Requestor Column -->
      <ng-container matColumnDef="requestor">
          <th mat-header-cell *matHeaderCellDef width ="10%">Requestor </th>
          <td mat-cell *matCellDef="let element" width ="10%"> {{element?.requestor}} </td>
      </ng-container>
  
      <!-- Age Column -->
      <ng-container matColumnDef="age">
          <th mat-header-cell *matHeaderCellDef width ="10%"> Age </th>
          <td mat-cell *matCellDef="let element" width ="10%"> {{element?.age}} </td>
      </ng-container>

      <ng-container matColumnDef="action" >
        <th mat-header-cell *matHeaderCellDef  width ="5%">  </th>
        <td mat-cell *matCellDef="let element" width ="5%">
           <mat-icon (click)="redirectToDetailsPage(element.ndc,element.dataSourceId)"  style="margin-bottom: 2px;">
          <img src="/assets/img/icons/ico.12.arwrgt.svg" alt="">
      </mat-icon>
    </td>
      </ng-container>

      <tr mat-row (click)="selectedRow = row" [ngClass]="{ 'highlight': row === selectedRow }" *matRowDef="let row; columns: displayedColumns;"></tr>


  </table>
   <table>
      <tr *ngIf="dataSource?.data.length==0">
          <td style="text-align: center;" [attr.colspan]="displayedColumns.length">
              No Records Found!
          </td>
      </tr>
  </table>
<!-- <span (click)="openImportWizardQuanrantinedDrug()">Show More ></span> -->
</div>
