import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'dashboard-module',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'order-module',
    loadChildren: () => import('./order/order.module').then(m => m.OrderModule)
  },
  {
    path: 'customer-mgmt-module',
    loadChildren: () => import('./customer-management/customer-management.module').then(m => m.CustomerManagementModule)
  },
  {
    path: 'fleet-mgmt-module',
    loadChildren: () => import('./fleet-management/fleet-management.module').then(m => m.FleetManagementModule)
  },
  {
    path: 'drug-mgmt-module',
    loadChildren: () => import('./drug-management/drug-management.module').then(m => m.DrugManagementModule)
  },
  {
    path: 'im-tool-module',
    loadChildren: () => import('./im-tool/im-tool.module').then(m => m.IMToolModule)
  },
  {
    path: 'er-tool-module',
    loadChildren: () => import('./er-tool/er-tool.module').then(m => m.ERToolModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class InternalUserRoutingModule { }
