/* tslint:disable:max-line-length */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';
import { ERToolComponent } from './components/er-tool.component';
import { routePaths } from '../../config/app-constants';

const routes: Routes = [
  {
    path: routePaths.ER_TOOL,
    component: ERToolComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ERToolRoutingModule { }