import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appNumberValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NumberValidatorDirective,
      multi: true
    }
  ]
})
export class NumberValidatorDirective implements Validator {
  
  // Regular expression to match only positive numbers (integer and decimal)
  private validPositiveNumberRegex = /^[+]?\d*\.?\d+$/;  // Accepts integers and decimals but no negative numbers

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
 // Return null if value is null or undefined (valid case)
 if (value === null || value === undefined) {
  return null;
}
    // Check if value matches the positive number pattern
    if (this.validPositiveNumberRegex.test(value)) {
      return null; // Valid positive number
    }

    return { 'invalidPositiveNumber': true }; // Invalid number
  }
}
