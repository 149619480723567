// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// @ts-ignore
const env = window.env || {};

export const environment = {
  production: true,
  apiUrl: {
    reportRepository: env.reportRepository,
    reportSubscription: env.reportSubscription,
    configurationService: env.configurationService,
    authorizationService: env.authorizationService,
    formularyDataService: env.formularyDataService,
    notificationService: env.notificationService,
    productService: env.productService,
    orderService: env.orderService,
    inventoryService: env.inventoryService,
    pivotUIService: env.pivotUIService,
    fileUploadService: env.fileUploadService,
    websocketService: env.websocketService,
    salesforceService: env.salesforceService,
    viewstoreService: env.viewstoreService,
    viewsHub: env.viewsHub,
    kstreamsHpcAggregator: env.kstreamsHpcAggregator,
    atpDispenseratioStream: env.atpDispenseratioStream,
    kstreamsDowntimeAggregator: env.kstreamsDowntimeAggregator,
    blobService: env.blobService,
    logService: env.logService,
    iotDeviceMgmtService: env.iotDeviceMgmtService,
    fleetConfigService: env.fleetConfigService,
    imToolService: env.imToolManagementService,
    rpoToolService: env.rpoToolManagementService,
    parataStandardInterfaceService: env.parataStandardInterfaceService,
    erToolService: env.erToolManagementService,

  },
  reportUrl: env.reportUrl,
  formularyProcessPath: env.formularyProcessPath,
  formularyResultPath: env.formularyResultPath,
  PopupTime: 1, // to get the popup notification after 1hour
  auth0ClientID: env.auth0ClientID,
  auth0Domain: env.auth0Domain,
  auth0Audience: env.auth0Audience,
  launchDarklyClientId: env.launchDarklyClientId,
  logLevel: env.logLevel
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
