import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { State } from 'src/app/store/state';
import { ERToolService} from '../services/er-tool.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { getLaunchDarklyFlags } from 'src/app/store/selectors/assets.selector';

@Component({
  selector: 'app-er-tool', 
  templateUrl: './er-tool.component.html',
  styleUrl: './er-tool.component.scss'
})
export class ERToolComponent {

}
