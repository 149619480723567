import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DeploymentTypeMetadata, FeatureSelectedEventArgs, UserInputPropertySource } from '../../models/deploymentForm.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from 'src/app/shared/services/toast.service';
import * as constants from 'src/app/config/app-constants';
import { FleetManagerActions } from 'src/app/store/actions';
import { FleetConfigurationService } from '../../services/fleet-configuration.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state';
import { SystemFormService } from '../../services/system-form.service';
import { FleetManagementService } from '../../services/fleet-management.service';
import { MatAccordion } from '@angular/material/expansion';
import { AuthService } from 'src/app/auth/auth.service';

export interface DeploymentFormDialogComponentData {
  edgeDeviceId: string;
  provisioned: boolean;
}

/**
 * Dialog containing the form for a full edge deployment; to deploy a pre-provisioned device or redeploy a provisioned device.
 * Utilizes metadata from the Fleet Management API to drive deployment options and related settings dynamically.
 */
@Component({
  selector: 'app-deployment-form-dialog',
  templateUrl: './deployment-form-dialog.component.html',
  styleUrls: ['./deployment-form-dialog.component.scss']
})
export class DeploymentFormDialogComponent implements OnInit {

  /** Static cache of deployment metadata used to create dynamic form components. */
  static systemFormMetadata: DeploymentTypeMetadata[];
  /** Data structure used to track all deployment-level properties available in the metadata with associated. */
  static deploymentLevelPropertyMap: Map<string, { propMetadata: UserInputPropertySource, featureReferences: Set<string>, required: boolean }>;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  provisioned: boolean;
  selectedDeploymentType: string;
  accordionCollapsed: boolean = false;
  canEditAdvancedOptions: boolean = false;
  orderedKeys: string[] = [];

  /** The parent form for the entire deployment. */
  systemConfigForm: FormGroup;

  // Form helpers
  get organizationId(): string { return this.systemConfigForm.get('organizationId').value; }
  get deploymentLevelProperties(): FormGroup { return this.systemConfigForm.get('deploymentLevelProperties') as FormGroup; }
  get systems(): FormArray { return this.systemConfigForm.get('systems') as FormArray; }

  // Expose static data to HTML
  get getFormMetadata(): DeploymentTypeMetadata[] { return DeploymentFormDialogComponent.systemFormMetadata }
  get getDeploymentLevelPropertyMap(): Map<string, { propMetadata: UserInputPropertySource, featureReferences: Set<string>, required: boolean }> {
    return DeploymentFormDialogComponent.deploymentLevelPropertyMap;
  }

  /** Gets a list of each system type that is currently added to the form. */
  get distinctSystemTypes() {
    let names = this.systems.controls.map(control => control.get('name').value);
    return [...new Set(names)];
  }

  /** Gets a list of all features enabled throughout the form. */
  get distinctEnabledFeatures(): Set<string> {
    let enabledFeatures = new Set<string>();

    function aggregateFeatureNames(features: any[]) {
      features.forEach(feature => {
        enabledFeatures.add(feature.name);

        if (feature.optionalFeatures) {
          aggregateFeatureNames(feature.optionalFeatures);
        }
      });
    }

    aggregateFeatureNames(this.systems.value);
    return enabledFeatures;
  }

  /** Returns true if any deployment-level property controls are currently displayed to the user. */
  get deploymentLevelPropertiesShown(): boolean {
    return Object.keys(this.deploymentLevelProperties.controls).some(key => this.showDeploymentLevelProperty(key));
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public parentCompData: DeploymentFormDialogComponentData,
    public dialogRef: MatDialogRef<DeploymentFormDialogComponent>,
    public store: Store<State>,
    public systemFormService: SystemFormService,
    private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private toastService: ToastService,
    private fleetManagementService: FleetManagementService,
    private fleetConfigService: FleetConfigurationService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.provisioned = this.parentCompData.provisioned;
    let edgeDeviceId = this.parentCompData.edgeDeviceId;

    this.authService.profile$.subscribe({
      next: (profile) => {
        this.canEditAdvancedOptions = profile.hasRole("Parata Dev");
      },
      error: (err) => {
        console.error('Could not determine if current user has Parata Dev role, removing advanced options.');
        console.error(err);
        this.canEditAdvancedOptions = false;
      }
    });

    this.systemConfigForm = this.fb.group({
      edgeDeviceId: [edgeDeviceId, Validators.required],
      organizationId: ['', Validators.required],
      edgeDeviceFriendlyName: [''],
      deploymentLevelProperties: this.fb.group({}),
      systems: this.fb.array([])
    });

    if (!DeploymentFormDialogComponent.systemFormMetadata) {
      const loadingKey = 'LoadFormMetadata';
      this.ngxLoader.start(loadingKey);

      this.fleetConfigService.getConfigTemplates().subscribe({
        next: metadata => {
          this.ngxLoader.stop(loadingKey);
          DeploymentFormDialogComponent.deploymentLevelPropertyMap = this.systemFormService.getDeploymentLevelPropertyMap(metadata);

          // Use local static cache for metadata, this will change very rarely
          DeploymentFormDialogComponent.systemFormMetadata = metadata;

          this.loadDevice(edgeDeviceId);
        },
        error: err => {
          console.error(err);
          this.ngxLoader.stop(loadingKey);
          this.toastService.openToast('Error fetching system form data, cannot configure the device.', constants.ToastPanelType.error);
          this.dialogRef.close(false);
        }
      });
    }
    else {
      this.loadDevice(edgeDeviceId);
    }
  }

  /**
   * Load device details into form if applicable.
   * @param edgeDeviceId Edge device unique identifier
   */
  loadDevice(edgeDeviceId: string): void {
    if (!this.provisioned) {
      return;
    }

    const loadingKey = 'LoadProvisionedDevice';
    this.ngxLoader.start(loadingKey);

    this.fleetManagementService.getEdgeDeviceById(edgeDeviceId).subscribe({
      next: edgeDevice => {
        if (!edgeDevice) {
          console.error(`No provisioned device could be located with device ID ${edgeDeviceId}.`);
          this.toastService.openToast(`Provisioned device ${edgeDeviceId} could not be found.`, constants.ToastPanelType.error);
          this.dialogRef.close(false);
        }

        // Bind static controls
        this.systemConfigForm.patchValue({
          organizationId: edgeDevice.organizationId,
          edgeDeviceFriendlyName: edgeDevice.edgeDeviceFriendlyName
        });

        // Create and bind dynamic controls for deployment-level properties
        Object.keys(edgeDevice.deploymentLevelProperties || {}).forEach(propName => {
          if (this.getDeploymentLevelPropertyMap.has(propName)) {
            const propertyMapInfo = this.getDeploymentLevelPropertyMap.get(propName);
            this.toggleDeploymentLevelProperty(propertyMapInfo.propMetadata, true, propertyMapInfo.required);
          }
        });

        this.systemConfigForm.patchValue({
          deploymentLevelProperties: edgeDevice.deploymentLevelProperties
        });

        // Create and bind dynamic controls for systems
        edgeDevice.systems.forEach(system => {
          let systemForm = this.systemFormService.bindSystemForm(this.getSystemFormMetadata(system.name), system);
          systemForm.addControl('systemFriendlyName', new FormControl(system.systemFriendlyName));
          this.addSystem(systemForm, false);
        });

        this.ngxLoader.stop(loadingKey);
      },
      error: err => {
        console.error(err);
        this.ngxLoader.stop(loadingKey);
        this.toastService.openToast(`Unexpected error fetching provisioned device ${edgeDeviceId}.`, constants.ToastPanelType.error);
        this.dialogRef.close(false);
      }
    });
  }

  /** Returns true if a given deployment-level property should be displayed to the user. */
  showDeploymentLevelProperty(key: string): boolean {
    const control = this.deploymentLevelProperties.get(key) as FormControl;

    if (!control) {
      console.warn('Key could not be found in deployment level property collection.')
      return false;
    }

    if (this.canEditAdvancedOptions || control.hasValidator(Validators.required)) {
      return true;
    }

    return false;
  }

  /**
   * Event handler invoked whenever optional features are added.
   * @param args Feature selected event data.
   */
  onFeatureSelect(args: FeatureSelectedEventArgs): void {
    args.featureMetadata.deploymentLevelRequiredParameters.forEach(param => this.toggleDeploymentLevelProperty(param, args.selected, true));
    args.featureMetadata.deploymentLevelOptionalParameters.forEach(param => this.toggleDeploymentLevelProperty(param, args.selected, false));
  }

  /**
   * Adds or removes a deployment-level property from the form.
   * @param source Metadata of the deployment level property.
   * @param selected Whether this property is being toggled on or off.
   * @param required Whether the property is required on the form.
   */
  toggleDeploymentLevelProperty(source: UserInputPropertySource, selected: boolean, required: boolean): void {
    if (selected) {
      if (this.deploymentLevelProperties.contains(source.name)) {
        return;
      }

      this.deploymentLevelProperties.addControl(source.name, this.systemFormService.createDynamicFormControl(source, required));
      this.orderedKeys.push(source.name); // Add key to orderedKeys
    } else {
      const featureMap = this.getDeploymentLevelPropertyMap.get(source.name).featureReferences as any;

      if (!featureMap.isDisjointFrom(this.distinctEnabledFeatures)) {
        return;
      }

      if (this.deploymentLevelProperties.contains(source.name)) {
        this.deploymentLevelProperties.removeControl(source.name);
        this.orderedKeys = this.orderedKeys.filter(key => key !== source.name); // Remove key from orderedKeys
      }
    }
  }

  getFeatureList(systemType: string, features: Array<any>): string {
    return features.map(feature => {
      let systemFormMetadata = this.getSystemFormMetadata(systemType);
      let featureMetadata = systemFormMetadata.optionalFeatures.find(optionalFeature => optionalFeature.name === feature.name);
      return featureMetadata.friendlyName;
    }).join(', ') || 'N/A';
  }

  getSystemFormMetadata(systemName: string): DeploymentTypeMetadata {
    return DeploymentFormDialogComponent.systemFormMetadata.find(data => data.name === systemName);
  }

  getSystemTypeCount(systemName: string): number {
    return this.systems.value.filter(system => system.name === systemName).length;
  }

  onAddSystemClick(systemName: string, event: Event): void {
    event?.stopPropagation();
    const form = this.getSystemFormMetadata(systemName);
    this.addSystem(this.systemFormService.getSystemForm(form), true);
  }

  addSystem(systemForm: FormGroup, editMode: boolean): void {
    systemForm.addControl('isEditMode', new FormControl(editMode));

    this.onFeatureSelect({
      featureMetadata: this.getSystemFormMetadata(systemForm.get('name').value),
      selected: true
    });

    this.systems.push(systemForm);
  }

  editSystem(systemFormGroup: FormGroup): void {
    systemFormGroup.patchValue({
      isEditMode: true
    });
  }

  validateAndSaveSystem(systemFormGroup: FormGroup): void {
    systemFormGroup.markAllAsTouched();

    if (systemFormGroup.valid) {
      systemFormGroup.patchValue({
        isEditMode: false
      });
    }
  }

  deleteSystem(systemIndex: number): void {
    this.onFeatureSelect({
      featureMetadata: this.getSystemFormMetadata(this.systems.at(systemIndex).get('name').value),
      selected: false
    });

    this.systems.removeAt(systemIndex);
  }

  toggleAccordion(): void {
    if (this.accordionCollapsed) {
      this.accordionCollapsed = false;
      this.accordion.openAll();
    }
    else {
      this.accordionCollapsed = true;
      this.accordion.closeAll();
    }
  }

  /** Submit the form to the Fleet Management API for deployment. */
  submit(): void {
    if (!this.systemConfigForm.valid) {
      this.toastService.openToast('Invalid deployment - double check the form for validation issues.', constants.ToastPanelType.error);
      this.systemConfigForm.markAllAsTouched();
      return;
    }

    let deploymentForm = this.systemConfigForm.getRawValue();

    // Remove isEditMode boolean used for UI-specific tracking
    deploymentForm.systems.forEach(system => {
      this.systemFormService.setFeatureReferences(this.getSystemFormMetadata(system.name), system);
      delete system.isEditMode;
    });

    const loadingKey = 'SystemConfigurationSubmit';
    this.ngxLoader.start(loadingKey);

    let submit$ = this.provisioned
      ? this.fleetConfigService.putConfigTemplate(deploymentForm)
      : this.fleetConfigService.postConfigTemplate(deploymentForm);

    submit$.subscribe({
      next: () => {
        setTimeout(() => {
          this.ngxLoader.stop(loadingKey);
          this.toastService.openToast('Systems configured successfully.', constants.ToastPanelType.done);
          this.store.dispatch(FleetManagerActions.getIOTDeviceDetails());
          this.dialogRef.close(true);
        }, 5000);
      },
      error: error => {
        this.ngxLoader.stop(loadingKey);
        console.error(error);

        if (error.status === 500) {
          this.toastService.openToast('Unable to configure systems.', constants.ToastPanelType.error);
        }
        else {
          this.toastService.openToast(error.error, constants.ToastPanelType.error);
        }
      }
    });
  }
}
