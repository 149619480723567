<div class="inner-mat-dialog title-section flex justify-between content-stretch items-stretch flex-col">

  <div mat-dialog-title id="drug-details-header">
    <div class="flex justify-start content-center items-center flex-row">
      <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
        <h3>DRUG IMAGES</h3>
      </div>
    </div>
  </div>

  <div class="row flex flex-col justify-center content-center items-center w-full gap-[1rem]"
    style="margin-bottom: .8rem;">
    <div class="flex flex-col basis-1/5 data-source-container">
      <span class="label">Data Source</span>
      <mat-form-field>
        <mat-select [(ngModel)]="dataSource" ngDefaultControl>
          <mat-option *ngFor="let data of listOfDataSource" [value]="data.value">{{data?.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex flex-col basis-1/5">
      <button mat-button aria-label="Save Drug" style="margin-top: 1.5rem;" (click)="getSliderImage()"
        class="btn-sm btn-primary" type="button">Search</button>
    </div>
  </div>

  <!-- Image Slideshow Section -->
  <div class="images-container">
    <div *ngIf="slides?.length > 0" class="images-slideshow">
      <div class="imagesSlides fade" *ngFor="let slide of slides; let i = index">
        <div *ngIf="currentIndex === i" class="image-wrapper">
          <img [src]="slide.serialImage" class="consistent-image" />
          <span class="image-label">{{slide.imageName}}</span>
        </div>
      </div>
      <!-- Previous and Next Buttons -->
      <a *ngIf="currentIndex !== 0" class="slider-btn previous" (click)="slideShow(false)">
        < </a>
          <a *ngIf="currentIndex !== slides.length - 1" class="slider-btn next" (click)="slideShow(true)"> > </a>
    </div>

    <!-- Placeholder when there are no images -->
    <div *ngIf="slides?.length === 0" class="images-placeholder">
      <img src='/assets/img/no-image.png' alt="No Image" class="no-image" />
    </div>
  </div>

  <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full xs:flex xs:flex-col">
    <button mat-button class="btn-blue-light btn-md" (click)="dialogRef.close()" id="CancelNewUser" aria-label="Cancel"
      type="button">Cancel</button>
  </mat-dialog-actions>
</div>
