<app-card title="">
  <div title class="inventory_ctrl xs:flex xs:justify-start xs:content-start xs:items-start flex flex-row justify-between content-center items-center">
      <h5 class="ucase m-0 text-primaryblue xs:basis-full basis-1/2" tabindex="0">COMMON DRUG DATABASE</h5>

  </div>
  <section class="main-section">
      <div class="sticky_search">
          <div class="p-16 bg-white">
              <div class="flex justify-between content-center items-center xs:flex xs:flex-col sm:flex sm:flex-row md:flex md:flex-row lg:flex lg:flex-row">


                  <div class="flex justify-between content-center items-center flex-row basis-[60%]">
                    <button class="btn-danger basis-[20%]" (click)="openImportWizardFileDownloadComponent()">Download MDL</button>
                  </div>


              </div>
          </div>
      </div>


      <table mat-table  [dataSource]="dataSource" multiTemplateDataRows  class="mat-elevation-z8 main-table" >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef width ="30%"> Name </th>
          <td mat-cell *matCellDef="let element" width ="30%"><span style="font-weight: bold;">
            <span *ngIf="element.isExpanded">
              <mat-icon class="cross-wizard">
              <img src="/assets/img/icons/ico.16.arwup.svg" alt="">
          </mat-icon>
        </span>
        <span *ngIf="!element.isExpanded">
          <mat-icon class="cross-wizard">
          <img src="/assets/img/icons/ico.16.arwdown.svg" alt="">
      </mat-icon>
    </span>
            {{element.name}}</span> </td>
      </ng-container>
        <ng-container matColumnDef="ndc">
        <th mat-header-cell *matHeaderCellDef width ="10%"> Drug Number </th>
        <td mat-cell *matCellDef="let element" width ="10%"> {{element.ndc}} </td>
        </ng-container>
        <ng-container matColumnDef="countryCode">
          <th mat-header-cell *matHeaderCellDef width ="10%"> Country </th>
          <td mat-cell *matCellDef="let element" width ="10%"> {{element.countryCode}} </td>
          </ng-container>
          <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef width ="10%"> Source  </th>
            <td mat-cell *matCellDef="let element" width ="10%"> {{element.source}} </td>
            </ng-container>

        <ng-container matColumnDef="strength">
        <th mat-header-cell *matHeaderCellDef width ="10%"> Strength </th>
        <td mat-cell *matCellDef="let element" width ="10%"> {{element.strength}} </td>
        </ng-container>
        <ng-container matColumnDef="manufacturer">
        <th mat-header-cell *matHeaderCellDef width ="10%"> Manufacturer </th>
        <td mat-cell *matCellDef="let element" width ="10%"> {{element.manufacturer}} </td>
        </ng-container>
        <ng-container matColumnDef="system">
          <th mat-header-cell *matHeaderCellDef width ="10%"> Quarantine Reason </th>
          <td mat-cell *matCellDef="let element" width ="10%"> {{element.system}} </td>
          </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" style="padding:0px" [attr.colspan]="columnsToDisplay.length">
              <div class="row queue-element-detail" [@detailExpand]="(element.isExpanded) ? 'expanded' : 'collapsed'">


                <div>
                  <span>Coming soon.... </span>
                </div>

              </div>
          </td>
      </ng-container>

   <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
   <tr  mat-row *matRowDef="let element; columns: columnsToDisplay;"
   class="queue-element-row"
   [class.queue-expanded-row]="element.isExpanded" (click)="element.isExpanded = !element.isExpanded;"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
   class="queue-detail-row"></tr>


      </table>
      <!-- <table class="no-result" *ngIf="dataSource.data.length==0">
        <tr>
            <td [attr.colspan]="columnsToDisplay.length" style="text-align: center; padding:1rem;">
              <span>No results found</span>
            </td>
        </tr>

    </table> -->

  </section>
</app-card>
