<div class="inner-mat-dialog title-section flex justify-between content-stretch items-stretch flex-col">
  <div mat-dialog-title id="drug-details-header">
    <div class="flex justify-start content-center items-center flex-row">

      <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
        <h3>MDL Download Wizard</h3>
      </div>

      <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
        <mat-icon (click)="this.dialogRef.close()" class="cross-wizard">
          <img src="/assets/img/icons/ico.16.clear.svg" alt>
        </mat-icon>
      </div>

    </div>
  </div>

  
  <div mat-dialog-content>
    <div class="flex flex-col" fxLayoutGap="20px">
    <div style="margin-top: 1rem;" class="flex justify-start content-center items-center flex-row" >
      <div style="padding-left: 3.5rem;" class=" sm:basis-4/5 basis-4/5">
      <span>
        <mat-icon class="cross-wizard">
          <img src="/assets/img/icons/ico.asterisk.svg" alt="">  
        </mat-icon>
      </span>
      <span style="padding-left: 6px"> Select a Master data Source 
      </span>
    </div>   
    </div>  

    <!-- Source Selector -->
    <div class="flex justify-center content-center items-center flex-row" >
    <mat-form-field  floatLabel="never" class="sm:basis-4/5 basis-4/5">     
      <mat-select [(ngModel)]="selectedSource" (selectionChange)="onSourceChange()">
        <mat-option *ngFor="let source of sources" [value]="source.value">
          {{ source.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </div>
  
    <div style="margin-top: 1rem;" class="flex justify-start content-center items-center flex-row" >
      <div style="padding-left: 3.5rem;" class=" sm:basis-4/5 basis-4/5">
      <span>
        <mat-icon class="cross-wizard">
          <img src="/assets/img/icons/ico.asterisk.svg" alt="">  
        </mat-icon>
      </span>
      <span style="padding-left: 6px"> Select a File Export 
      </span>                 
    </div>
    </div>  


    <!-- File Export Selector -->
    <div class="flex justify-center content-center items-center flex-row" >
    <mat-form-field  floatLabel="never" class="sm:basis-4/5 basis-4/5">    
      <mat-select [(ngModel)]="selectedExport" (selectionChange)="onExportChange()">
        <mat-option *ngFor="let file of fileExports" [value]="file.value">
          {{ file.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </div>

    <div style="margin-top: 1rem;" class="flex justify-start content-center items-center flex-row" >
      <div style="padding-left: 3.5rem;" class="sm:basis-4/5 basis-4/5">
      <span>
        <mat-icon class="cross-wizard">
          <img src="/assets/img/icons/ico.asterisk.svg" alt="">  
        </mat-icon>
      </span>
      <span style="padding-left: 6px"> Select a Format
      </span>                  
    </div> 
    </div>


    <!-- Format Selector -->
    <div class="flex justify-center content-center items-center flex-row" >
    <mat-form-field  floatLabel="never" class="sm:basis-4/5 basis-4/5">  
      <mat-label>Select a Format</mat-label>
      <mat-select [(ngModel)]="selectedFormat">
        <mat-option *ngFor="let format of formats" [value]="format.value">
          {{ format.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </div>
  </div>
  </div>
  
  <div mat-dialog-actions class="justify-center">
    <button mat-button class="btn-blue-light btn-md btn-done m-l-16" [disabled]="!isFormValid()" (click)="onDownload()" type="button" 
        aria-label="Next">Download</button>
    <button mat-button class="btn-blue-red btn-md" (click)="dialogRef.close()" id="CancelNewUser"
        aria-label="Cancel" type="button">Cancel</button>
  </div>
  


</div>
