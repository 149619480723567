<div class="inner-mat-dialog title-section flex justify-between content-stretch items-stretch flex-col">
  <div class="inner-mat-dialog flex flex-col justify-between content-stretch items-stretch w-full">
    <div mat-dialog-title id="drug-details-header">
      <div class="flex justify-start content-center items-center flex-row">

        <div class="flex flex-row justify-start content-stretch items-stretch h-full basis-1/2">
          <h3>EDIT {{drugName}}</h3>
        </div>
        <div class="flex flex-row justify-end content-stretch items-stretch h-full basis-1/2">
          <mat-icon (click)="closeDialog(true)" class="cross-wizard">
            <img src="/assets/img/icons/ico.16.clear.svg" alt="">
          </mat-icon>
        </div>

      </div>
    </div>
    <form [formGroup]="saveModelForm" style="overflow:scroll">
      <div class="drug-info flex flex-row">

        <div class="drug-left-side">
          <div class="image-div flex justify-between content-center items-center flex-row">
            <div class="flex flex-row">
              <img (click)="openImportWizardImageSlide()" class="img" [src]="images.serialImage" alt="drug image">
            </div>

            <div class="flex flex-row justify-center content-center items-center h-full">
              <button *ngIf="this.drugCharacteristicsModel.quarantined"
                [style.background-color]="this.drugCharacteristicsModel.quarantinedColor" class="btn-status">
                {{this.drugCharacteristicsModel.quarantined}}</button>

              <button *ngIf="this.drugCharacteristicsModel.drugStatus"
                [style.background-color]="this.drugCharacteristicsModel.drugStatusColor" class="btn-status">
                {{this.drugCharacteristicsModel.drugStatus}}</button>

              <p class="country-value">{{drugCharacteristicsModel.countryCode}}
                <img class="flag-img" *ngIf="drugCharacteristicsModel.countryCode?.length>0"
                  [src]="drugCharacteristicsModel.countryCode === 'USA'? '/assets/img/country/usa.png': drugCharacteristicsModel.countryCode === 'CAN'? '/assets/img/country/canadian.webp':'/assets/img/no-image.png' "
                  style=" height: 25px; padding: 10px; width: 25px;" />
              </p>

            </div>

          </div>

          <div>
            <div class="flex flex-col">
              <h5>Characteristics</h5>
              <div>

                <div class="row-info flex flex-row gap-[1rem] pl-1rem">
                  <div class="flex flex-col basis-[30%]">
                    <span class="label">Generic Name</span>
                    <input type="text" appNoLeadingSpace formControlName="genericName"
                      [ngClass]="{ 'section-disabled': isDisableEditForSource }">
                    <mat-error *ngIf="saveModelForm.get('genericName')?.errors as errors">
                      <div *ngIf="errors['leadingSpace']">{{ errors['leadingSpace'] }}</div>
                    </mat-error>
                  </div>

                  <div class="flex flex-col basis-[30%]">
                    <span class="label">Drug Name</span>
                    <input type="text" appNoLeadingSpace formControlName="drugName"
                      [ngClass]="{ 'section-disabled': isDisableEditForSource }">
                    <mat-error *ngIf="saveModelForm.get('drugName')?.errors as errors">
                      <div *ngIf="errors['leadingSpace']">{{ errors['leadingSpace'] }}</div>
                    </mat-error>
                  </div>


                  <div class="flex flex-col basis-[20%]">
                    <span class="label">Drug Number</span>
                    <input type="text" formControlName="drugId" [ngClass]="{ 'section-disabled': true }">
                  </div>

                  <div class="flex flex-col basis-[20%]">
                    <span class="label">Strength</span>
                    <input type="text" formControlName="strength"
                      [ngClass]="{ 'section-disabled': isDisableEditForSource }">
                    <div *ngIf="saveModelForm.controls.strength.touched">
                      <mat-error *ngIf="saveModelForm.controls.strength.errors?.required">Strength is
                        required</mat-error>
                      <mat-error
                        *ngIf="!saveModelForm.controls.strength.errors?.required && saveModelForm.controls.strength.errors?.positiveRealNumber">
                        Must be 1-9, decimal or combo (e.g., 1 / 2). No other symbols.
                      </mat-error>
                    </div>
                  </div>

                </div>
                <div class="row-info flex flex-row gap-[1rem] pl-1rem">
                  <div class="flex flex-col basis-[20%]">
                    <span class="label">Active</span>
                    <mat-form-field>
                      <mat-select formControlName="drugStatusId" [disabled]="isDisableEditForSource" ngDefaultControl>
                        <mat-option *ngFor="let data of listOfDrugStatus"
                          [value]="data.value">{{data?.label}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex flex-col basis-[30%]">
                    <span class="label">Manufacturer</span>
                    <mat-form-field class="w-full" [ngClass]="{ 'section-disabled': isDisableEditForSource }">
                      <input type="text" aria-label="Number" matInput [formControl]="manufacturerId"
                        [matAutocomplete]="autoManufacturer">

                      <mat-autocomplete #autoManufacturer="matAutocomplete"
                        [displayWith]="displayFnManufacturer.bind(this)">
                        <mat-option *ngFor="let data of listOfManufacturer" [value]="data.value">
                          {{data.label}}
                        </mat-option>
                        <mat-option *ngIf="isLoading" class="is-loading">
                          <mat-spinner diameter="25"></mat-spinner>
                        </mat-option>
                      </mat-autocomplete>

                    </mat-form-field>
                    <mat-error *ngIf="manufacturerId.hasError('forbiddenNames')">
                      You should enter value from suggested one only.
                      <strong>'{{manufacturerId.errors.forbiddenNames.value}}'</strong> is not
                      allowed.
                    </mat-error>
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">OTC</span>
                    <mat-form-field>
                      <mat-select formControlName="otcId" [disabled]="isDisableEditForSource" ngDefaultControl>
                        <mat-option *ngFor="let data of listOfOTC" [value]="data.key">{{data?.value}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Niosh</span>
                    <mat-form-field>
                      <mat-select formControlName="niosh" [disabled]="isDisableEditForSource" ngDefaultControl>
                        <mat-option *ngFor="let data of listOfNiosh" [value]="data.value">{{data?.value}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row-info flex flex-row gap-[1rem] pl-1rem">
                  <div class="flex flex-col basis-[20%]">
                    <span class="label">AntiBiotic</span>
                    <mat-form-field>
                      <mat-select formControlName="antibiotics" [disabled]="isDisableEditForSource" ngDefaultControl>
                        <mat-option [value]="true">true
                        </mat-option>
                        <mat-option [value]="false">false
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="flex flex-col basis-[30%]">
                    <span class="label">Distributor</span>
                    <mat-form-field [ngClass]="{ 'section-disabled': isDisableEditForSource }">
                      <input type="text" aria-label="Number" matInput [formControl]="distributorId"
                        [matAutocomplete]="autoDistributor">
                      <mat-autocomplete #autoDistributor="matAutocomplete"
                        [displayWith]="displayFnDistributor.bind(this)">
                        <mat-option *ngFor="let data of listOfDistributor" [value]="data.value">
                          {{data.label}}
                        </mat-option>
                        <mat-option *ngIf="isLoading" class="is-loading">
                          <mat-spinner diameter="25"></mat-spinner>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="distributorId.hasError('forbiddenNames')">
                      You should enter value from suggested one only.
                      <strong>'{{distributorId.errors.forbiddenNames.value}}'</strong> is not
                      allowed.
                    </mat-error>
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Unit of Use</span>
                    <mat-form-field>
                      <mat-select formControlName="unitOfUse" [disabled]="isDisableEditForSource" ngDefaultControl>
                        <mat-option value="1">Yes</mat-option>
                        <mat-option value="0">No</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Unit Dose</span>
                    <mat-form-field>
                      <mat-select formControlName="unitDose" [disabled]="isDisableEditForSource" ngDefaultControl>
                        <mat-option value="1">Yes</mat-option>
                        <mat-option value="0">No</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <hr />
                </div>
                <div class="row-info flex flex-row gap-[1rem] pl-1rem">

                  <div class="flex flex-col basis-[35%]">
                    <span class="label">Storage Condtions</span>
                    <input type="text" appNoLeadingSpace formControlName="storageConditions"
                      [ngClass]="{ 'section-disabled': isDisableEditForSource }">
                    <mat-error *ngIf="saveModelForm.get('storageConditions')?.errors as errors">
                      <div *ngIf="errors['leadingSpace']">{{ errors['leadingSpace'] }}</div>
                    </mat-error>
                  </div>
                  <div class="flex flex-col basis-[20%]">
                    <span class="label">Generic Number/GPI #</span>
                    <input type="text" appNoLeadingSpace formControlName="gpi"
                      [ngClass]="{ 'section-disabled': isDisableEditForSource }">
                    <mat-error *ngIf="saveModelForm.get('gpi')?.errors as errors">
                      <div *ngIf="errors['leadingSpace']">{{ errors['leadingSpace'] }}</div>
                    </mat-error>
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Quarantine Reason</span>
                    <mat-form-field>
                      <mat-select formControlName="quarantineId" ngDefaultControl>
                        <mat-option *ngFor="let data of quarantineReasonList" [value]="data.value">{{data?.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex flex-col basis-[20%]">
                    <span class="label">Oral Solid</span>
                    <mat-form-field>
                      <mat-select [disabled]="isRecommendedShow" formControlName="oralSolid" ngDefaultControl>
                        <mat-option [value]="true">true
                        </mat-option>
                        <mat-option [value]="false">false
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <hr />
                </div>

              </div>
              <!-- ---------------------- -->
              <h5>CDDB Standard Fields</h5>
              <div>
                <div class="row-info flex flex-row gap-[1rem] pl-1rem">
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Unit</span>
                    <mat-form-field>
                      <mat-select formControlName="unitId">
                        <mat-option *ngFor="let data of unitList" [value]="data.value">{{data?.label}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Schedule</span>
                    <mat-form-field>
                      <mat-select formControlName="scheduleId">
                        <mat-option *ngFor="let data of scheduleList" [value]="data.key">{{data?.value}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Route Of Admin</span>
                    <mat-form-field class="w-full">
                      <input type="text" aria-label="Number" matInput [formControl]="routeOfAdminId"
                        [matAutocomplete]="autoRoa">

                      <mat-autocomplete #autoRoa="matAutocomplete" [displayWith]="displayFnRoa.bind(this)">
                        <mat-option *ngFor="let data of listOfRoa" [value]="data.value">
                          {{data.label}}
                        </mat-option>
                        <mat-option *ngIf="isLoading" class="is-loading">
                          <mat-spinner diameter="25"></mat-spinner>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="routeOfAdminId.hasError('forbiddenNames')">
                      You should enter value from suggested one only.
                      <strong>'{{routeOfAdminId.errors.forbiddenNames.value}}'</strong> is not
                      allowed.
                    </mat-error>
                  </div>

                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Dosage Form</span>
                    <mat-form-field class="w-full">
                      <input type="text" aria-label="Number" matInput [formControl]="dosageFormId"
                        [matAutocomplete]="autoDosageForm">

                      <mat-autocomplete #autoDosageForm="matAutocomplete"
                        [displayWith]="displayFnDosageForm.bind(this)">
                        <mat-option *ngFor="let data of listOfDosageForm" [value]="data.id">
                          {{data.description}}
                        </mat-option>
                        <mat-option *ngIf="isLoading" class="is-loading">
                          <mat-spinner diameter="25"></mat-spinner>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="dosageFormId.hasError('forbiddenNames')">
                      You should enter value from suggested one only.
                      <strong>'{{dosageFormId.errors.forbiddenNames.value}}'</strong> is not
                      allowed.
                    </mat-error>
                  </div>

                </div>

                <div class="row-info flex flex-row gap-[1rem] pl-1rem">

                  <div class="flex flex-col basis-[25%]">
                    <span class="label">Shape</span>
                    <mat-form-field>
                      <mat-select formControlName="shapeId" ngDefaultControl>
                        <mat-option *ngFor="let data of shapeList" [value]="data.value">{{data?.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="flex flex-col basis-[25%]">
                    <div class="flex flex-row">
                      <span class="fc-red">*</span><span class="label">Coatings</span>
                    </div>
                    <mat-form-field>
                      <mat-select formControlName="coatingId" ngDefaultControl>
                        <mat-option *ngFor="let data of listOfCoating" [value]="data.key">{{data?.value}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div
                      *ngIf="saveModelForm.controls.coatingId.hasError('required') && saveModelForm.controls.coatingId.touched">
                      <mat-error *ngIf="saveModelForm.controls.coatingId.hasError('required')">Coating is
                        required</mat-error>
                    </div>
                  </div>
                  <div class="flex flex-col basis-[25%] mr-20px">
                    <div class="flex flex-row">
                      <span class="fc-red">*</span> <span class="label">Color</span>
                    </div>
                    <mat-form-field class="full-width">
                      <mat-chip-list #chipList>
                        <mat-chip *ngFor="let color of selectedColors" [removable]="true" (removed)="remove(color)"
                          class="mat-standard-chip" [ngStyle]="{'margin-top': '10px', 'margin-left': '10px'}">
                          {{color}}
                          <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <input #colorInput [formControl]="colorId" [matAutocomplete]="autoColor"
                          [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          (matChipInputTokenEnd)="add($event)" class="input-field">
                      </mat-chip-list>
                      <mat-autocomplete #autoColor="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let color of filteredColors | async" [value]="color">
                          {{color}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>

                    <div *ngIf="colorId.errors && colorId.touched">
                      <mat-error *ngIf="colorId.hasError('required')">Color is required</mat-error>
                      <mat-error *ngIf="colorId.hasError('maxColors')">Maximum 2 colors allowed</mat-error>
                    </div>
                  </div>

                  <div class="flex flex-col basis-[25%] mr-20px">
                    <div class="flex flex-row">
                      <span class="fc-red">*</span><span class="label">Scoring</span>
                    </div>
                    <mat-form-field>
                      <mat-select formControlName="scoringId" ngDefaultControl>
                        <mat-option *ngFor="let data of listOfScoaring" [value]="data.key">{{data?.value}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div
                      *ngIf="saveModelForm.controls.scoringId.hasError('required') && saveModelForm.controls.scoringId.touched">
                      <mat-error *ngIf="saveModelForm.controls.scoringId.hasError('required')">scoringId is
                        required</mat-error>
                    </div>
                  </div>
                  <hr />
                </div>

                <div class="row-info flex flex-row gap-[1rem] pl-1rem">
                  <!-- 2D Barcode Field -->
                  <div class="flex flex-col basis-[25%]">
                    <span class="label">2D Barcodes</span>
                    <input type="text" appNoLeadingSpace formControlName="packageBarcode2d" />
                    <mat-error *ngIf="saveModelForm.get('packageBarcode2d')?.errors as errors">
                      <div *ngIf="errors['leadingSpace']">{{ errors['leadingSpace'] }}</div>
                    </mat-error>
                  </div>

                  <div class="flex flex-col basis-[25%]">
                    <div class="flex flex-row">
                      <span class="fc-red">*</span> <span class="label">Package Type</span>
                    </div>

                    <mat-form-field>
                      <input type="text" aria-label="Number" matInput [formControl]="packageTypeId"
                        [matAutocomplete]="autoPcakgeType">
                      <mat-autocomplete #autoPcakgeType="matAutocomplete"
                        [displayWith]="displayFnPacakgeType.bind(this)">
                        <mat-option *ngFor="let data of listOfPackageType" [value]="data.value">
                          {{data.label}}
                        </mat-option>
                        <mat-option *ngIf="isLoading" class="is-loading">
                          <mat-spinner diameter="25"></mat-spinner>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="packageTypeId.errors?.required">
                      Package Type is Required
                    </mat-error>
                    <mat-error *ngIf="packageTypeId.hasError('forbiddenNames')">
                      You should enter value from suggested one only.
                      <strong>'{{packageTypeId.errors.forbiddenNames.value}}'</strong> is not
                      allowed.
                    </mat-error>
                  </div>

                </div>
              </div>

              <h5>General Attributes</h5>
              <div class="row flex gap-[1rem] pt-10px pl-1rem">
                <div class="flex flex-col basis-[16.6%]">
                  <span class="label">Length (inches)</span>
                  <input type="number" appNumberValidator [readonly]="isRecommendedShow" formControlName="length">
                  <mat-error *ngIf="saveModelForm.get('length')?.errors?.invalidPositiveNumber">
                    Invalid input! Please enter a valid positive number.
                  </mat-error>
                </div>
                <div class="flex flex-col basis-[16.6%]">
                  <span class="label">Width (inches)</span>
                  <input type="number" appNumberValidator [readonly]="isRecommendedShow" formControlName="width">
                  <mat-error *ngIf="saveModelForm.get('width')?.errors?.invalidPositiveNumber">
                    Invalid input! Please enter a valid positive number.
                  </mat-error>
                </div>
                <div class="flex flex-col basis-[16.6%]">
                  <span class="label">Thickness (inches)</span>
                  <input type="number" [readonly]="isRecommendedShow" appNumberValidator min="0" formControlName="thickness">
                  <mat-error *ngIf="saveModelForm.get('thickness')?.errors?.invalidPositiveNumber">
                    Invalid input! Please enter a valid positive number.
                  </mat-error>
                </div>
                <div class="flex flex-col basis-[16.6%]">
                  <span class="label">Diameter</span>
                  <input type="number" [readonly]="isRecommendedShow" appNumberValidator formControlName="atpTcgDiameter">
                  <mat-error *ngIf="saveModelForm.get('atpTcgDiameter')?.errors?.invalidPositiveNumber">
                    Invalid input! Please enter a valid positive number.
                  </mat-error>
                </div>
                <div class="flex flex-col basis-[16.6%]">
                  <span class="label">Piece Weight (grams)</span>
                  <input type="number" [readonly]="isRecommendedShow" appNumberValidator formControlName="pieceWeight">
                  <mat-error *ngIf="saveModelForm.get('pieceWeight')?.errors?.invalidPositiveNumber">
                    Invalid input! Please enter a valid positive number.
                  </mat-error>
                </div>
                <div class="flex flex-col basis-[16.6%]">
                  <span class="label">Diagonal</span>
                  <input type="number" min="0" [readonly]="isRecommendedShow" appNumberValidator formControlName="diagonal">
                  <mat-error *ngIf="saveModelForm.get('diagonal')?.errors?.invalidPositiveNumber">
                    Invalid input! Please enter a valid positive number.
                  </mat-error>
                </div>
              </div>

              <div class="row flex gap-[1rem] pt-10px pl-1rem">
                <div class="flex flex-col basis-[28%]">
                  <span class="label">Classification Shape</span>
                  <mat-form-field>
                    <mat-select [disabled]="isRecommendedShow" formControlName="classificationShapeId" ngDefaultControl>
                      <mat-option *ngFor="let data of listOfClassificationShape" [value]="data.value">{{data?.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="flex flex-col basis-[16%]">
                  <span class="label">Pill Volume</span>
                  <input type="number" appNumberValidator formControlName="atpTcgPillVolume" />
                  <mat-error *ngIf="saveModelForm.get('atpTcgPillVolume')?.errors?.invalidPositiveNumber">
                    Invalid input! Please enter a valid positive number.
                  </mat-error>
                </div>

                <div class="flex flex-col basis-[10%]">
                  <span class="label">T1</span>
                  <input type="number" appNumberValidator formControlName="atpTcgT1">
                  <mat-error *ngIf="saveModelForm.get('atpTcgT1')?.errors?.invalidPositiveNumber">
                    Invalid input! Please enter a valid positive number.
                  </mat-error>
                </div>

                <div class="flex flex-col basis-[10%]">
                  <span class="label">T2</span>
                  <input type="number" appNumberValidator formControlName="atpTcgT2">
                  <mat-error *ngIf="saveModelForm.get('atpTcgT2')?.errors?.invalidPositiveNumber">
                    Invalid input! Please enter a valid positive number.
                  </mat-error>
                </div>
                <div class="flex flex-col basis-[23%]">
                  <span class="label">30 Dram Capacity</span>
                  <input type="number" appNumberValidator formControlName="packageThirtyDramCapacity" value="" />
                  <mat-error *ngIf="saveModelForm.get('packageThirtyDramCapacity')?.errors?.invalidPositiveNumber">
                    Invalid input! Please enter a valid positive number.
                  </mat-error>
                </div>

              </div>
              <div class="flex flex-row gap-5 pt-10px pl-1rem">
                <div class="flex flex-col basis-[25%]">
                  <span class="label"> Imprint 1</span>
                  <input type="text" appNoLeadingSpace formControlName="packageImprint1" value="" />
                  <mat-error *ngIf="saveModelForm.get('packageImprint1')?.errors as errors">
                    <div *ngIf="errors['leadingSpace']">{{ errors['leadingSpace'] }}</div>
                  </mat-error>
                </div>
                <div class="flex flex-col basis-[25%]">
                  <span class="label"> Imprint 2</span>
                  <input type="text" appNoLeadingSpace formControlName="packageImprint2" value="" />
                  <mat-error *ngIf="saveModelForm.get('packageImprint2')?.errors as errors">
                    <div *ngIf="errors['leadingSpace']">{{ errors['leadingSpace'] }}</div>
                  </mat-error>
                </div>
                <div class="flex flex-col basis-[25%] mr-20px">
                  <div class="flex flex-row">
                    <span class="fc-red">*</span><span class="label">Country</span>
                  </div>
                  <mat-form-field>
                    <mat-select formControlName="country" ngDefaultControl [disabled]="isDisableEditForSource">
                      <mat-option *ngFor="let data of countryList" [value]="data.value">{{data?.label}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="flex flex-col basis-[25%]">
                  <div class="flex flex-row">
                    <span class="fc-red">*</span><span class="label">Drug Classification Type</span>
                  </div>
                  <mat-form-field>
                    <mat-select formControlName="drugClassificationType" ngDefaultControl
                      [disabled]="isDisableEditForSource">
                      <mat-option *ngFor="let data of listOfDrugClassificationType"
                        [value]="data.value">{{data?.label}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </div>
              <hr />
              <h5>Package</h5>
              <div class="flex flex-row gap-5 pt-10px pl-1rem">
                <!-- UPC Barcode Field -->
                <div class="flex flex-col basis-[15%]">
                  <div class="flex flex-row">
                    <span class="fc-red">*</span><span class="label">12 Digit Barcode (UPC)</span>
                  </div>
                  <input type="text" appDigitLengthValidator formControlName="packageBarcode" />
                  <div *ngIf="saveModelForm.get('packageBarcode')?.errors as errors">
                    <mat-error *ngIf="errors['invalidCharacters']">{{ errors['invalidCharacters'] }}</mat-error>
                    <mat-error *ngIf="errors['minLength']">{{ errors['minLength'] }}</mat-error>
                    <mat-error *ngIf="errors['maxLength']">{{ errors['maxLength'] }}</mat-error>
                  </div>
                </div>
                <div class="flex flex-col basis-[15%]">
                  <div class="flex flex-row">
                    <span class="fc-red">*</span><span class="label">Capacity</span>
                  </div>
                  <input type="text" appNoLeadingSpace formControlName="packageSize" value="" />
                  <div
                    *ngIf="saveModelForm.controls.packageSize.hasError('required') && saveModelForm.controls.packageSize.touched">
                    <mat-error *ngIf="saveModelForm.controls.packageSize.hasError('required')">Capacity is
                      required</mat-error>

                  </div>
                  <mat-error *ngIf="saveModelForm.get('packageSize')?.errors as errors">
                    <div *ngIf="errors['leadingSpace']">{{ errors['leadingSpace'] }}</div>
                  </mat-error>

                </div>
                <div class="flex flex-col basis-[16%]">
                  <span class="label">Width</span>
                  <input type="number" appNumberValidator formControlName="packageWidth" value="" />
                  <mat-error *ngIf="saveModelForm.get('packageWidth')?.errors?.invalidPositiveNumber">
                    Invalid input! Please enter a valid positive number.
                  </mat-error>
                </div>
                <div class="flex flex-col basis-[16%]">
                  <span class="label">Height</span>
                  <input type="number" appNumberValidator formControlName="packageHeight" value="" />
                  <mat-error *ngIf="saveModelForm.get('packageHeight')?.errors?.invalidPositiveNumber">
                    Invalid input! Please enter a valid positive number.
                  </mat-error>
                </div>
                <div class="flex flex-col basis-[16%]">
                  <span class="label">Depth</span>
                  <input type="number" appNumberValidator formControlName="packageDepth" value="" />
                  <mat-error *ngIf="saveModelForm.get('packageDepth')?.errors?.invalidPositiveNumber">
                    Invalid input! Please enter a valid positive number.
                  </mat-error>
                </div>
              </div>
              <hr />

              <h5 class="mr-1rem">Inventory</h5>
              <div class="flex flex-row pt-10px pl-1rem pb-point8rem">
                <div class="flex gap-5 mr-2rem">
                  <button class="btn-add-new" (click)="openImportWizardInventory(1)">Add New</button>
                  <button [ngClass]="{'btn-add-new':inventoryId!==0, 'btn-disabled':inventoryId===0}"
                    class="btn-add-new btn btn-sm" [disabled]="inventoryId===0"
                    (click)="openImportWizardInventory(2)">Destroy</button>
                  <button class="btn-add-new" (click)="openImportWizardInventory(3)">History</button>
                </div>
              </div>
              <div *ngFor="let inventory of inventoryList">
                <div *ngIf="!inventory.isDestroyed" class="flex flex-col div-border">
                  <div class="flex flex-row">
                    <div class="flex flex-col basis-[10%]">
                      <span class="label">Action</span>
                      <mat-radio-button class="p-btn" value="{{inventory.id}}" [disabled]="inventory.isDestroyed"
                        (change)="inventorySelect(inventory.id, $event.ehecked)">
                      </mat-radio-button>
                    </div>
                    <div class="flex flex-col basis-[23%]">
                      <span class="label">Inventory Number</span>
                      <span class="label-value">{{inventory.inventoryNumber}}</span>
                    </div>
                    <div class="flex flex-col basis-1/5">
                      <span class="label">Inventory</span>
                      <span class="label-value">{{inventory.inventoryQuantity}}</span>
                    </div>
                    <div class="flex flex-col basis-1/5">
                      <span class="label">Source</span>
                      <span class="label-value">{{inventory.source}}</span>
                    </div>
                    <div class="flex flex-col basis-1/5">
                      <span class="label">Added</span>
                      <span class="label-value">{{inventory.addedDate}}</span>
                    </div>
                    <div class="flex flex-col basis-1/5">
                      <span class="label">Owner</span>
                      <span class="label-value">{{inventory.owner}}</span>
                    </div>
                    <div class="flex flex-col basis-1/5">
                      <span class="label">Last Changed</span>
                      <span class="label-value">{{inventory.lastChangedDate}}</span>
                    </div>
                  </div>
                  <div class="flex flex-row">
                  </div>

                  <div class="flex flex-col basis-1/5">
                    <span class="label"> Inventory Comments</span>
                    <span class="label-value">{{inventory.comments}}</span>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="drug-right-side">
          <div>
            <table mat-table [dataSource]="dataSourceDevice" class="mat-elevation-z8" multiTemplateDataRows>
              <ng-container matColumnDef="deviceName">
                <th mat-header-cell *matHeaderCellDef class="w-100">Device Name</th>
                <td mat-cell *matCellDef="let element" class="w-100"><span class="fw-b">
                    <span *ngIf="element.isExpanded">
                      <mat-icon class="cross-wizard">
                        <img src="/assets/img/icons/ico.16.arwup.svg" alt="">
                      </mat-icon>
                    </span>
                    <span *ngIf="!element.isExpanded">
                      <mat-icon class="cross-wizard">
                        <img src="/assets/img/icons/ico.16.arwdown.svg" alt="">
                      </mat-icon>
                    </span>
                    {{element.deviceName}}</span> </td>
              </ng-container>

              <ng-container matColumnDef="drugStatus">
                <th mat-header-cell *matHeaderCellDef class="w-100">Drug Status</th>
                <td mat-cell *matCellDef="let element" class="w-100">
                  <!-- <button *ngIf="element.drugStatus" [style.background-color]="element.drugRecordColor"    class="btn-status"> {{element.drugStatus}}</button>
            </td> -->
              </ng-container>

              <ng-container matColumnDef="deviceStatus">
                <th mat-header-cell *matHeaderCellDef class="w-100">Device Status</th>
                <td mat-cell *matCellDef="let element" class="w-100">
                  <button *ngIf="element.deviceStatus"
                    [style.background-color]="this.getColorCodeByStatus(element.deviceStatus)" class="btn-status">
                    {{element.deviceStatus}}</button>
                </td>
              </ng-container>

              <ng-container matColumnDef="labOptimizedStatus">
                <th mat-header-cell *matHeaderCellDef class="w-100">lab status</th>
                <td mat-cell *matCellDef="let element" class="w-100">
                  <span class="lab-status">Lab Optimized
                    <span>{{element.labOptimizedStatus}}</span> </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="4">
                  <div class="row queue-element-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
                    <div *ngIf="element.deviceName==='ATP'" class="pb-point8rem">
                      <div class="flex flex-col div-border pb-point8rem">
                        <h5>Attributes</h5>

                        <div class="row row-info flex gap-[1rem] div-border">

                          <div class="flex flex-col basis-[18%]">
                            <span class="label">TCG Pill Type</span>
                            <mat-form-field>
                              <mat-select formControlName="atpTcgPillType">
                                <mat-option *ngFor="let data of listOfPillType" [value]="data.label">{{data?.label}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Drug Status</span>
                            <mat-form-field>
                              <mat-select formControlName="atpDrugStatus" ngDefaultControl>
                                <mat-option *ngFor="let data of listOfATPDrugStatus" [value]="data.label">
                                  {{data?.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Lab Optimize</span>
                            <mat-form-field>
                              <mat-select formControlName="atpLabOptimized" ngDefaultControl>
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                        </div>
                        <h5>Canister</h5>
                        <div class="row row-info flex gap-[1rem]">
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Canister Number</span>
                            <input type="text" appNoLeadingSpace value="" formControlName="atpCanisterNumber" />
                            <mat-error *ngIf="saveModelForm.get('atpCanisterNumber')?.errors as errors">
                              <div *ngIf="errors['leadingSpace']">{{ errors['leadingSpace'] }}</div>
                            </mat-error>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <button mat-button class="btn-blue-light btn-sm" style="margin-top:1.5rem;"> Find
                              New</button>
                          </div>
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Canister Capacity</span>
                            <!-- <span class="label-value">{{atpModel.canisterCapacity }}</span> -->
                            <input type="number" appNumberValidator value="" formControlName="canisterCapacity" />
                            <mat-error *ngIf="saveModelForm.get('canisterCapacity')?.errors?.invalidPositiveNumber">
                              Invalid input! Please enter a valid positive number.
                            </mat-error>

                          </div>
                          <div class="flex flex-col basis-[17%]">
                            <span class="label">High Cap Canister</span>
                            <!-- <span class="label-value">{{atpModel.highCapCanister }}</span> -->
                            <input type="number" appNumberValidator value="" formControlName="highCapCapacity" />
                            <mat-error *ngIf="saveModelForm.get('highCapCapacity')?.errors?.invalidPositiveNumber">
                              Invalid input! Please enter a valid positive number.
                            </mat-error>
                          </div>

                        </div>

                      </div>
                    </div>
                    <div *ngIf="element.deviceName==='PASS'" class="pb-point8rem">
                      <div class="flex flex-col pb-point8rem div-border">
                        <h5>Attributes</h5>

                        <div class="row row-info flex gap-[1rem]">
                          <div class="flex flex-col basis-1/5">
                            <span class="label">Drug Status</span>
                            <mat-form-field>
                              <mat-select [disabled]="isRecommendedShow" formControlName="passDrugStatus"
                                ngDefaultControl>
                                <mat-option *ngFor="let data of listOfPASSDrugStatus" [value]="data.label">
                                  {{data?.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">DNU Reason</span>
                            <span class="label-value">{{this.passModel.dnuReason}}</span>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Lab Optimize</span>
                            <mat-form-field>
                              <mat-select formControlName="passLabOptimized" ngDefaultControl>
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="row row-info flex gap-[1rem]">
                          <mat-radio-group [disabled]="isRecommendedShow" formControlName="recommendationType">
                            <mat-radio-button class="basis-[23%]" value="1"
                              [checked]="saveModelForm.value.recommendationType=='1'"> Canister ID</mat-radio-button>
                            <mat-radio-button class="basis-[23%]" value="2"
                              [checked]="saveModelForm.value.recommendationType=='2'"> Snap Canister ID
                            </mat-radio-button>
                          </mat-radio-group>

                        </div>

                      

                        <div class="row flex gap-[1rem] items-center"> 

                          <div class="flex flex-col basis-[23%] cursor-pointer" (click)="toggleCanisterDetails(true, false)"> 
                            <span *ngIf="passModel.canisterId?.length > 0" 
                                  class="label-fixed-value font-semibold"
                                  [ngClass]="{'text-green-600': isCanisterDetails, 'text-red-600': !isCanisterDetails}">
                              {{ passModel.canisterId }}
                              <mat-icon class="cross-wizard inline-block ml-2"> 
                                <img *ngIf="isCanisterDetails" src="/assets/img/icons/ico.16.arwup.svg" alt="Up">
                                <img *ngIf="!isCanisterDetails" src="/assets/img/icons/ico.16.arwdown.svg" alt="Down">
                              </mat-icon>
                            </span>
                          </div>
                        
                          <!-- Snap Canister Details Section -->
                          <div class="flex flex-col basis-[23%] cursor-pointer" (click)="toggleSnapCanisterDetails(false, true)">
                            <span *ngIf="passModel.snapCanisterId?.length > 0" 
                                  class="label-fixed-value font-semibold" 
                                  [ngClass]="{'text-green-600': isSnapCanisterDetails, 'text-red-600': !isSnapCanisterDetails}">
                              {{ passModel.snapCanisterId }}
                              <mat-icon class="cross-wizard inline-block ml-2">
                                <img *ngIf="isSnapCanisterDetails" src="/assets/img/icons/ico.16.arwup.svg" alt="Up">
                                <img *ngIf="!isSnapCanisterDetails" src="/assets/img/icons/ico.16.arwdown.svg" alt="Down">
                              </mat-icon>
                            </span>
                          </div>
                        
                          <!-- Button Section -->
                          <div class="flex justify-center items-center basis-[10%]">
                            <button mat-button class="btn-primary btn-sm mt-4" *ngIf="!isRecommendedShow" (click)="getRecommendedDrug()">
                              Recommend New
                            </button>
                          </div>
                        </div>

                      </div>
                      <div *ngIf="isCanisterDetails" class="flex flex-col">
                        <h5 style="padding: 20px 0px 20px 0px;">Canister Details</h5>
                       
                        <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                          <!-- Part Type Column -->
                          <ng-container matColumnDef="partType">
                            <mat-header-cell *matHeaderCellDef> Part Type </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.partType}} </mat-cell>
                          </ng-container>
                      
                          <!-- Part Column -->
                          <ng-container matColumnDef="part">
                            <mat-header-cell *matHeaderCellDef> Part </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.part}} </mat-cell>
                          </ng-container>
                      
                          <!-- Part No Column -->
                          <ng-container matColumnDef="partNo">
                            <mat-header-cell *matHeaderCellDef> Part No </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.partNo}} </mat-cell>
                          </ng-container>
                      
                          <!-- Header and Row Definitions -->
                          <mat-header-row *matHeaderRowDef="displayedDetailsColumns"></mat-header-row>
                          <mat-row *matRowDef="let row; columns: displayedDetailsColumns;"></mat-row>
                        </mat-table>
                      

                      </div>
                      <div *ngIf="isSnapCanisterDetails" class="flex flex-col">
                        <h5>Snap Canister Details</h5>
                        <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                          <!-- Part Type Column -->
                          <ng-container matColumnDef="partType">
                            <mat-header-cell *matHeaderCellDef> Part Type </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.partType}} </mat-cell>
                          </ng-container>
                      
                          <!-- Part Column -->
                          <ng-container matColumnDef="part">
                            <mat-header-cell *matHeaderCellDef> Part </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.part}} </mat-cell>
                          </ng-container>
                      
                          <!-- Part No Column -->
                          <ng-container matColumnDef="partNo">
                            <mat-header-cell *matHeaderCellDef> Part No </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.partNo}} </mat-cell>
                          </ng-container>
                      
                          <!-- Header and Row Definitions -->
                          <mat-header-row *matHeaderRowDef="displayedDetailsColumns"></mat-header-row>
                          <mat-row *matRowDef="let row; columns: displayedDetailsColumns;"></mat-row>
                        </mat-table>
                      

                      

                      </div>
                      <div *ngIf="isRecommendedShow" class="flex flex-col">
                        <h5 style="padding: 20px 0px 20px 0px;">Canister Recommendations</h5>
                        <table mat-table #outerSort="matSort" [dataSource]="dataSourceRecommendationDrug"
                          multiTemplateDataRows class="mat-elevation-z8 main-table" matSort>
                          <ng-container matColumnDef="isChecked">
                            <th mat-header-cell *matHeaderCellDef width="5px"> </th>
                            <td mat-cell *matCellDef="let element" width="5px">
                              <mat-radio-button (click)="canisterChecked(element, $event.checked)"></mat-radio-button>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="canisterRecipe">
                            <th mat-header-cell *matHeaderCellDef width="10px"> Canister Recipe </th>
                            <td mat-cell *matCellDef="let element" width="10px"><span class="fw-b">
                                <span *ngIf="expandedElement">
                                  <mat-icon class="cross-wizard">
                                    <img src="/assets/img/icons/ico.16.arwup.svg" alt="">
                                  </mat-icon>
                                </span>
                                <span *ngIf="!expandedElement">
                                  <mat-icon class="cross-wizard">
                                    <img src="/assets/img/icons/ico.16.arwdown.svg" alt="">
                                  </mat-icon>
                                </span>
                                {{element.canisterRecipe}}</span>

                            </td>
                          </ng-container>

                          <ng-container matColumnDef="matchLevel">
                            <th mat-header-cell *matHeaderCellDef width="10px"> Match Level </th>
                            <td mat-cell *matCellDef="let element" width="10px"> {{element.matchLevel}} </td>
                          </ng-container>
                          <ng-container matColumnDef="medName">
                            <th mat-header-cell *matHeaderCellDef width="10px"> Med Name(Drug Nummber) </th>
                            <td mat-cell *matCellDef="let element" width="10px"> {{element.medName}} </td>
                          </ng-container>

                          <ng-container matColumnDef="dItem">
                            <th mat-header-cell *matHeaderCellDef width="10px"> DItem # </th>
                            <td mat-cell *matCellDef="let element" width="10px"> {{element.dItem}} </td>
                          </ng-container>
                          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                          <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                              <div class="example-element-detail" *ngIf="element.canisters.data"
                                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
                                  <div *ngFor="let item of element.canisters.data">

                                    <div class="row row-info flex gap-[1rem]">
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Pill Type</span>
                                        <span class="label-value">{{item.pillType}}</span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Length</span>
                                        <span class="label-value"
                                          *ngIf="item.length!==this.saveModelForm.value.passLength">
                                          <img src="/assets/img/icons/ico.16.alert.svg" />
                                          <span class="fc-red">{{item.length}} </span>
                                        </span>
                                        <span class="label-value"
                                          *ngIf="item.length===this.saveModelForm.value.passLength">{{item.length}}
                                        </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Width</span>
                                        <span class="label-value"
                                          *ngIf="item.width!==this.saveModelForm.value.passWidth">
                                          <img src="/assets/img/icons/ico.16.alert.svg" />
                                          <span class="fc-red">{{item.width}} </span>
                                        </span>

                                        <span class="label-value"
                                          *ngIf="item.width===this.saveModelForm.value.passWidth">{{item.width}}
                                        </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Thickness</span>
                                        <span class="label-value"
                                          *ngIf="item.thickness!==this.saveModelForm.value.passThickness">
                                          <img src="/assets/img/icons/ico.16.alert.svg" />
                                          <span class="fc-red">{{item.thickness}} </span>
                                        </span>
                                        <span class="label-value"
                                          *ngIf="item.thickness===this.saveModelForm.value.passThickness">{{item.thickness}}
                                        </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label"> Piece Weight</span>

                                        <span class="label-value"
                                          *ngIf="item.weight!==this.saveModelForm.value.passPieceWeight">
                                          <img src="/assets/img/icons/ico.16.alert.svg" />
                                          <span class="fc-red">{{item.weight}} </span>
                                        </span>
                                        <span class="label-value"
                                          *ngIf="item.weight===this.saveModelForm.value.passPieceWeight">{{item.weight}}
                                        </span>
                                      </div>

                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label"> Diagonal</span>

                                        <span class="label-value"
                                          *ngIf="item.diagonal!==this.saveModelForm.value.passDiagonal">
                                          <img src="/assets/img/icons/ico.16.alert.svg" />
                                          <span class="fc-red">{{item.diagonal}} </span>
                                        </span>
                                        <span class="label-value"
                                          *ngIf="item.diagonal===this.saveModelForm.value.passDiagonal">{{item.diagonal}}
                                        </span>
                                      </div>
                                    </div>
                                    <!--Snap Canister Id-->
                                    <div class="row row-info flex gap-[1rem]"
                                      *ngIf="saveModelForm.value.recommendationType=='2'">
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Snap Body</span>
                                        <span class="label-value">{{item.snapBody}}</span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">SnapDiv Block</span>
                                        <span class="label-value">{{item.snapDivBlock}} </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">SnapGuid Block</span>
                                        <span class="label-value">{{item.snapGuideBlock}} 
                                          
                                          <span class="help_icon"  [matTooltip]="'Part No: ' + (item.snapGuideBlock || 'N/A') + ' || Part: ' + (item.snapGuideBlockPart || 'N/A')" matTooltipPosition="after" 
                                          matTooltipClass="custom_tooltip">
                                          <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
                                        </span>
                                     
                                        </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">SnapBrush Guide</span>
                                        <span class="label-value">{{item.snapBrushBlock}} 
                                          
                                        </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Snap Brush</span>
                                        <span class="label-value">{{item.snapBrush}} 
                                          <span class="help_icon"  [matTooltip]="'Part No: ' + (item.snapBrush || 'N/A') + ' || Part: ' + (item.snapBrushPart || 'N/A')" matTooltipPosition="after" 
                                          matTooltipClass="custom_tooltip">
                                          <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
                                        </span>
                                        </span>
                                      </div>
                                    </div>

                                    <!--Canister ID-->
                                    <div class="row row-info flex gap-[1rem]"
                                      *ngIf="saveModelForm.value.recommendationType=='1'">
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Guid Block</span>
                                        <span class="label-value">{{item.guideBlock}}
                                          <span class="help_icon"  [matTooltip]="'Part No: ' + (item.snapGuideBlock || 'N/A') + ' || Part: ' + (item.snapGuideBlockPart || 'N/A')" matTooltipPosition="after" 
                                          matTooltipClass="custom_tooltip">
                                          <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
                                        </span>
                                        </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Division Block</span>
                                        <span class="label-value">{{item.divisionBlock}} 
                                          <span class="help_icon"  [matTooltip]="'Part No: ' + (item.divisionBlock || 'N/A') + ' || Part: ' + (item.snapDivisionBlockPart || 'N/A')" matTooltipPosition="after" 
                                          matTooltipClass="custom_tooltip">
                                          <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
                                        </span>
                                        </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Brush ID</span>
                                        <span class="label-value">{{item.brushId}}
                                          <span class="help_icon"  [matTooltip]="'Part No: ' + (item.snapBrush || 'N/A') + ' || Part: ' + (item.snapBrushPart || 'N/A')" matTooltipPosition="after" 
                                          matTooltipClass="custom_tooltip">
                                          <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
                                        </span>
                                       </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">GelPad ID</span>
                                        <span class="label-value">{{item.gelPadId}} 
                                          <span class="help_icon"  [matTooltip]="'Part No: ' + (item.snapGelPadPartNo || 'N/A') + ' || Part: ' + (item.snapGelPadPart || 'N/A')" matTooltipPosition="after" 
                                          matTooltipClass="custom_tooltip">
                                          <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
                                        </span>
                                        </span>
                                      </div>
                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label">Handle ID</span>
                                        <span class="label-value">
                                          {{ item.handleIdId }}
                                          <span 
                                            class="help_icon" 
                                            [matTooltip]="'Part No: ' + (item.handlePartNo || 'N/A') + ' || Part: ' + (item.handlePart || 'N/A')" 
                                            matTooltipPosition="after" 
                                            matTooltipClass="custom_tooltip"
                                          >
                                            <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="Info">
                                          </span>
                                        </span>
                                      </div>
                                      

                                      <div class="flex flex-col basis-[13%]">
                                        <span class="label"> Slot Location</span>
                                        <span class="label-value">{{item.slotLocation}}  <span class="help_icon"  [matTooltip]="'Part No: ' + (item.slotLocationPartNo || 'N/A') + ' || Part: ' + (item.slotLocationPart || 'N/A')" matTooltipPosition="after" 
                                          matTooltipClass="custom_tooltip">
                                          <img src="../../../../../assets/img/icons/ico.16.info.svg" alt="">
                                        </span></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                            [class.example-element-row]="element.canisters"
                            [class.example-expanded-row]="expandedElement === element" (click)="toggleRow(element)">
                          </tr>

                          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row">
                          </tr>

                        </table>

                        <div
                          class="recommended-bottom flex flex-row justify-between content-stretch items-stretch w-full"
                          style="padding: 19px 0px 18px 0px;">
                          <div>
                            <span href="#">Show More ></span>
                          </div>
                          <div>
                            <span (click)="cancelSletectedCanisterId()" class="fc-red pr-1rem">Cancel</span>
                            <span (click)="saveCanister()">Assign Selected Canister ></span>
                          </div>

                        </div>

                      </div>
                    </div>
                    <div *ngIf="element.deviceName==='MAX'" class="pb-point8rem">
                      <div class="flex flex-col">
                        <h5>Attributes</h5>

                        <div class="row row-info flex gap-[1rem] div-border">
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">13 Dram DNU</span>
                            <mat-form-field>
                              <mat-select formControlName="maxThirtyDramDnu">
                                <mat-option [value]="1">Yes</mat-option>
                                <mat-option [value]="0">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Do Not Use?</span>
                            <mat-form-field>
                              <mat-select formControlName="maxDoNotUse">
                                <mat-option [value]="1">Yes</mat-option>
                                <mat-option [value]="0">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Drug Status</span>
                            <mat-form-field>
                              <mat-select formControlName="maxDrugStatus" ngDefaultControl>
                                <mat-option *ngFor="let data of listOfMAXDrugStatus" [value]="data.label">
                                  {{data?.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Lab Optimize</span>
                            <mat-form-field>
                              <mat-select formControlName="maxLabOptimized" ngDefaultControl>
                                <mat-option [value]="true">Yes</mat-option>
                                <mat-option [value]="false">No</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <h5>Cell Settings</h5>

                        <div class="row row-info flex gap-[1rem]">
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Cell Height</span>
                            <input type="number" step="0.25" min="1.00" max="4.75" formControlName="maxCellHeight"
                              (change)="AttributeValidation('maxCellHeight', $event)">
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Max Cell Width</span>
                            <input type="text" formControlName="maxCellWidth"
                              (change)="AttributeValidation('maxCellWidth', $event)">
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Baffle</span>
                            <input type="number" onkeypress="return !(event.charCode == 46)" min="1" max="4" step="1"
                              formControlName="maxBaffle" (change)="AttributeValidation('maxBaffle', $event)">
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Pressure</span>
                            <input type="number" onkeypress="return !(event.charCode == 46)" step="5" min="15" max="60"
                              formControlName="maxPressure" (change)="AttributeValidation('maxPressure', $event)">
                          </div>

                        </div>
                        <div class="row row-info flex gap-[1rem]">
                          <div class="flex flex-col basis-[17%]">
                            <span class="label">Cell Capacity</span>
                            <input type="text" value="" formControlName="cellCapacity" />

                          </div>
                          <div class="flex flex-col basis-[17%]">
                            <span class="label">Super Cell Capacity</span>
                            <input type="text" value="" formControlName="superCellCapacity" />
                          </div>
                          <div class="flex flex-col basis-[17%]">
                            <span class="label">Super Cell Only?</span>
                            <mat-checkbox class="mt-1rem" formControlName="isSuperCellOnly">
                            </mat-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="element.deviceName==='SYNMED'">
                      <div class="flex flex-col div-border">
                        <h5>Attributes</h5>

                        <div class="row row-info flex gap-[1rem]">

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Drug Status</span>
                            <mat-form-field>
                              <mat-select formControlName="synmedDrugStatus" ngDefaultControl>
                                <mat-option *ngFor="let data of listOfSynmedDrugStatus"
                                  [value]="data.label">{{data.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Lab Optimize</span>
                            <mat-form-field>
                              <mat-select formControlName="synmedLabOptimized" ngDefaultControl>
                                <mat-option *ngFor="let data of synmedLabOptimizeds"
                                  [value]="data.value">{{data.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Fragile Flag</span>
                            <mat-form-field>
                              <mat-select formControlName="fragileFlag" ngDefaultControl>
                                <mat-option *ngFor="let data of fragileFlags"
                                  [value]="data.value">{{data.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">DNU Reason</span>
                            <mat-form-field>
                              <mat-select formControlName="nonCountReasonId" ngDefaultControl>
                                <mat-option *ngFor="let data of listOfDnuReason"
                                  [value]="data.value">{{data.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                        <h5>Settings</h5>
                        <div class="row row-info flex gap-[1rem]">
                          <div class="flex flex-col basis-[25%]">
                            <span class="label">Preferred Pipette Size</span>
                            <mat-form-field>
                              <mat-select formControlName="preferredPipetteSize" ngDefaultControl>
                                <mat-option *ngFor="let value of preferredPipetteSize"
                                  [value]="value">{{value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Lid Hole</span>
                            <mat-form-field>
                              <mat-select formControlName="lidHole" ngDefaultControl>
                                <mat-option *ngFor="let value of preferredLidHoleSize"
                                  [value]="value">{{value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <!-- <div class="flex flex-col basis-[20%]">
                            <span class="label">Large Container Capacity</span>
                            <input type="number" onkeypress="return !(event.charCode == 46)" min="0"
                            formControlName="largeCanisterCapacity">
                          </div> -->
                          <div class="flex flex-col basis-[20%]">
                            <span class="label">Lrg Pipette Drop Height</span>
                            <mat-form-field>
                              <mat-select formControlName="largePipetteDropHeight" ngDefaultControl>
                                <mat-option *ngFor="let value of largePipetteDropHeight"
                                  [value]="value">{{value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Mass Index</span>
                            <mat-form-field>
                              <mat-select formControlName="massIndex" ngDefaultControl>
                                <mat-option *ngFor="let value of massIndex" [value]="value">{{value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="row row-info flex gap-[1rem]">

                          <div class="flex flex-col basis-[25%]">
                            <span class="label">Preffered Container Size</span>
                            <mat-form-field>
                              <mat-select formControlName="preferredContainerSize" ngDefaultControl>
                                <mat-option *ngFor="let value of preferredContainerSize"
                                  [value]="value">{{value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Preffered Lid Hole</span>
                            <mat-form-field>
                              <mat-select formControlName="preferredLidHoleSize" ngDefaultControl>
                                <mat-option *ngFor="let value of preferredLidHoleSize"
                                  [value]="value">{{value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <!-- <div class="flex flex-col basis-[20%]">
                            <span class="label">Small Container Capacity</span>
                            <input type="number" onkeypress="return !(event.charCode == 46)" min="0"
                            formControlName="smallCanisterCapacity">
                          </div> -->
                          <div class="flex flex-col basis-[20%]">
                            <span class="label">Sm Pipette Drop Height</span>
                            <mat-form-field>
                              <mat-select formControlName="smallPipetteDropHeight" ngDefaultControl>
                                <mat-option *ngFor="let value of smallPipetteDropHeight"
                                  [value]="value">{{value}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="flex flex-col basis-[15%]">
                            <span class="label">Toggle</span>
                            <mat-form-field>
                              <mat-select formControlName="toggle" ngDefaultControl>
                                <mat-option *ngFor="let data of toggleOptions"
                                  [value]="data.value">{{data.label}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="element.deviceName==='BEACON'">
                      <span>Coming soon....</span>
                    </div>
                  </div>
                </td>
              </ng-container>
              <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="queue-element-row"
                [class.queue-expanded-row]="element.isExpanded" (click)="element.isExpanded = !element.isExpanded;">
              </tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="queue-detail-row"></tr>
            </table>
          </div>
        </div>


      </div>
    </form>
    <mat-dialog-actions class="flex flex-row justify-end content-end items-end w-full xs:flex xs:flex-col">
      <button mat-button class="btn-blue-light btn-md" (click)="closeDialog(true)" id="CancelNewUser"
        aria-label="Cancel" type="button">Cancel</button>
      <button mat-button aria-label="Done" (click)="updateDrug()"
        [ngClass]="{'btn-primary':saveModelForm?.valid, 'btn-disabled':!saveModelForm?.valid || isRecommendedShow}"
        [disabled]="!saveModelForm?.valid" class="btn-primary btn-md" type="button">Done
      </button>
    </mat-dialog-actions>
  </div>
</div>
