import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OrgRole } from '../../models/org-role';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NoDataRow, TableGroup } from '../../../../shared/models/ui/table-group';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OrganizationService } from '../../../../internal-user/customer-management/services/organization.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { OrgUser } from '../../models/org-user';
import { AuthService } from '../../../../auth/auth.service';
import { UserService } from '../../services/user.service';
import { RoleService } from '../../services/role.service';
import { forkJoin, throwError } from 'rxjs';
import { SiteService } from 'src/app/external-user/inventory/services/site.service';
import { Site } from 'src/app/external-user/inventory/models/site';
import { OrgUsersAndRoles } from '../../models/org-users-with-roles';
import { OrgUserRoles } from '../../models/org-user-roles';
import { Menu } from 'src/app/config/app-constants';
import { AuthorizationService } from 'src/app/internal-user/customer-management/services/authorization.service';
import { Role } from '../../../../shared/models/auth/role';
import { ReportService } from 'src/app/external-user/reports/services/report.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from 'src/app/shared/services/logger.service';
import {Sort} from '@angular/material/sort';
import { OrgLevelAdminService } from 'src/app/internal-user/customer-management/services/orgleveladmin.service';
import { OrgUserRole } from '../../models/org-user-role';

interface TableRow extends TableGroup, NoDataRow, OrgUsersAndRoles {
  index?: number;
}

interface FilterData {
  text?: string;
  roleId?: string;
  organizationId?: string;
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  selectedRoleId: string = '';
  parentOrganization: Site = null;
  sites: Site[] = [];
  selectedSite: Site = null;
  selectedSiteId: string = '';
  availableSites: Site[] = [];
  availableSitesForEditForm : Site[] = [];

  constructor(
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private authService: AuthService,
    private userService: UserService,
    private roleService: RoleService,
    private authorizationService: AuthorizationService,
    private organizationService: OrganizationService,
    private toastService: ToastService,
    private reportService: ReportService,
    private loggerService: LoggerService,
    private orgLevelAdminService:OrgLevelAdminService,
    private siteService: SiteService,
  ) {
    this.formGroup = new FormGroup({
      userLastName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('[a-zA-Z]+')]),
      userFirstName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('[a-zA-Z]+')]),
      userEmail: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
      roleId: new FormControl('', [Validators.required]),
      siteId: new FormControl(''),
    });

    this.editUserFormGroup = new FormGroup({
      name: new FormControl(''),
      email: new FormControl(''),
      roleId: new FormControl('', [Validators.required]),
      siteId: new FormControl('', [Validators.required]),
    });

    this.activeGroup = { groupName: 'Active Users', htmlId: 'UserManagementActiveUsers', expanded: true };
    this.inActiveGroup = { groupName: 'Inactive Users', htmlId: 'UserManagementInactiveUsers', expanded: true };
  }

  /** Whether the number of selected elements matches the total number of rows. */
  get isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.selectableRows.length;
    return numSelected === numRows;
  }

  private get selectableRows() {
    return (this.dataSource.data || [])
      .filter((row) => 'userId' in row && row.enabled);
  }

  isPM = false;
  isIM = false;
  isParataAdmin = false;
  isCDDBViewer = false;
  isOrgAdmin = false;
  orgType: string;  
  isResendInvitationVisible = false;
  isResetPasswordVisible = false;
  isSelected = '';
  isFilterVisible = true;
  isEmailExist = false;
  roles: OrgRole[] = [];
  userList: OrgUsersAndRoles[] = [];
  activeUsers: OrgUsersAndRoles[] = [];
  inActiveUsers: OrgUsersAndRoles[] = [];
  @Input() isGearIconClicked = false;
  @Input() isOrgLevel = false;
  paramOrgId: string;
  paramParentOrgId: string;

  displayedColumns: string[];
  editingColumns: string[];
  dataSource = new MatTableDataSource<TableRow>([]);
  selection = new SelectionModel<OrgUsersAndRoles>(true, []);
  activeGroup: TableGroup;
  inActiveGroup: TableGroup;
  filter: FilterData;
  loggedEmail: string;
  formGroup: FormGroup;
  @ViewChild('formDirective', { static: false }) private formDirective: NgForm;

  editingUser: OrgUsersAndRoles;
  editUserFormGroup: FormGroup;
  inactivatedUser: OrgUsersAndRoles;
  inactivatedUserPanelTimeout: any = null;

  /** Get the selected organization and load the roles and list of users */
  ngOnInit() {
    this.isOrgLevel = this.orgLevelAdminService.getOrgLevelRowValue();
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);

    const ngxLoaderKey = 'load-profile';
    this.ngxLoader.start(ngxLoaderKey);

    this.authService.profile$.subscribe((profile) => {
      this.setProfileProperties(profile);
  
      this.initializeColumns();
      this.updateAddFormValidation();
      this.showResendInvtnAndResetPwd();

      if (this.isGearIconClicked) {
        this.handleGearIconClicked(profile, ngxLoaderKey);
      }
      else{
        this.handleCustomerManagementFlow(ngxLoaderKey);
      }
    }, (response) => this.toastService.stopLoadingAndShowError(response, '', ngxLoaderKey));
  }

  private setProfileProperties(profile) {
    this.loggedEmail = profile.email;
    this.orgType = profile.orgType;
    this.isPM = profile.hasRole(Role.pharmacy);
    this.isIM = profile.hasRole(Role.implementationMgr);
    this.isParataAdmin = profile.hasRole(Role.admin);
    this.isOrgAdmin = profile.hasRole(Role.orgAdmin);
    this.isCDDBViewer = profile.hasRole(Role.ert)
    
    if (this.isGearIconClicked && this.isParataAdmin) {
      this.isOrgLevel = false;
    }
    
    if (this.isGearIconClicked && this.isOrgAdmin) {
      this.isOrgLevel = true;
    }
  }

  private handleGearIconClicked(profile, ngxLoaderKey: string) {
    this.paramOrgId = profile.organizationId;
    this.paramParentOrgId = profile.parentOrganizationId;
    
    this.initializeBasedOnOrgLevel(ngxLoaderKey);
  }

  private handleCustomerManagementFlow(ngxLoaderKey: string) {
    this.route.parent.params.subscribe(({ id }) => {
        this.organizationService.getByID(id).subscribe((org) => {
            this.paramOrgId = org.organizationId;
            this.paramParentOrgId = org.parent_Account_Id;

            this.initializeBasedOnOrgLevel(ngxLoaderKey);
        });
    });
  }

  private initializeBasedOnOrgLevel(ngxLoaderKey: string) {
    if (this.isOrgLevel) {
      this.setupRoleChangeListener();
      this.loadOrganizationAndSites(ngxLoaderKey);
    } else {
      this.loadUsersRoles(ngxLoaderKey);
    }
  }

  initializeColumns() {
    this.displayedColumns = this.isOrgLevel 
      ? ['name', 'email', 'role', 'site', 'actions']
      : ['name', 'email', 'role', 'actions'];

    this.editingColumns = this.isOrgLevel
      ? ['name', 'email', 'editRole', 'editSite', 'editActions']
      : ['name', 'email', 'editRole', 'editActions'];
  }

  loadOrganizationAndSites(ngxLoaderKey) {
    forkJoin({
      parentOrganization: this.organizationService.getByID(this.paramParentOrgId),
      sites: this.siteService.getSitesForParentOrg(this.paramParentOrgId)
    }).subscribe(
      ({ parentOrganization, sites }) => {
        this.parentOrganization = parentOrganization;
        this.sites = sites.filter(site => site.enabled === true);
        this.availableSites = this.sites;
        this.availableSitesForEditForm = this.sites;
        this.selectedSiteId = this.parentOrganization.organizationId;
        this.loadUsersRoles(ngxLoaderKey);
      },
      (error) => this.toastService.stopLoadingAndShowError(error, 'Unable to load organization and sites!', ngxLoaderKey)
    );
  }

  setupRoleChangeListener() {
    this.formGroup.get('roleId').valueChanges.subscribe((roleId) => {
      this.updateAvailableSites(roleId, 'add');
    });
  
    this.editUserFormGroup.get('roleId').valueChanges.subscribe((roleId) => {
      this.updateAvailableSites(roleId, 'edit');
    });
  }
  
  updateAvailableSites(roleId: string, formType: 'add' | 'edit') {
    const selectedRole = this.roles.find(role => role.roleId === roleId);
    const sites = selectedRole?.name === 'OrgAdmin' ? [this.parentOrganization] : this.sites;
  
    if (formType === 'add') {
      const previousSites = this.availableSites; // Store the previous value
      this.availableSites = sites;
  
      // Reset siteId if the available sites have changed
      if (previousSites !== this.availableSites) {
        this.formGroup.get('siteId').setValue('');
      }
    } else {
      const previousSites = this.availableSitesForEditForm; // Store the previous value
      this.availableSitesForEditForm = sites;
  
      // Reset siteId for the edit form if the available sites have changed
      if (previousSites !== this.availableSitesForEditForm) {
        this.editUserFormGroup.get('siteId').setValue('');
      }
    }
  }

  updateAddFormValidation() {
    const siteIdControl = this.formGroup.get('siteId');
    if (this.isOrgLevel) {
      siteIdControl.setValidators([Validators.required]);
    } else {
      siteIdControl.clearValidators();
    }
    siteIdControl.updateValueAndValidity();
  }

  onSiteFilterChange() {
    this.loadUsers();
  }

  loadUsersRoles(ngxLoaderKey) {
    this.roleService.getRolesForOrg(this.paramParentOrgId, this.orgType, this.isGearIconClicked, this.isOrgLevel).subscribe((response) => {
      this.roles = response;
      this.ngxLoader.stop(ngxLoaderKey);
    }, (response) => this.toastService.stopLoadingAndShowError(response,
      'Unable to load roles!', ngxLoaderKey));
    this.loadUsers();
  }


  showResendInvtnAndResetPwd() {
    if (this.orgType == "External") {
      this.isResendInvitationVisible = true;
    }
    else if (this.orgType == "Internal") {
      if (this.isGearIconClicked) {
        this.isResendInvitationVisible = false;
      }
      else {
        this.isResendInvitationVisible = true;
      }
    }
  }

  emailExist() {
    const emailExist = this.userList.filter(userlist => userlist.email == this.formGroup.get('userEmail').value);
    if (emailExist.length == 1) {
      const loadingKey = 'Email already exist!';
      this.toastService.stopLoadingAndShowError(
        'Email already exist!', loadingKey);
    }
    else {
      this.authorizationService.checkUserExists(this.formGroup.get('userEmail').value)
        .subscribe((response) => {
          if (response == true) {
            this.isEmailExist = true;
            const loadingKey = 'Email already exist!';
            this.toastService.stopLoadingAndShowError(
              'Email already exist!', loadingKey);
          }
          else {
            this.isEmailExist = false;
          }
        },
          catchError((error: HttpErrorResponse) => {
            this.isEmailExist = false;
            let errorMessage: string;
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `${error.error.message}`;
            } else {
              // server-side error
              errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
            }
            this.loggerService.logError('this.authorizationService.getUser - user email exits check: Error: ', errorMessage);
            return throwError(error);
          }));
    }
  }

  loadUsers() {
    const ngxLoaderKey = 'load-users';
    this.ngxLoader.start(ngxLoaderKey);

    this.userService.getUsersAndRolesForOrg(this.isOrgLevel? this.selectedSiteId : this.paramOrgId, this.orgType, this.isGearIconClicked)
      .subscribe((users) => {
        this.ngxLoader.stop(ngxLoaderKey);
        this.userList = users;
        this.setUsersToTableData(users);
      },
        catchError((error: HttpErrorResponse) => {
          let errorMessage: string;
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
          }
          this.toastService.stopLoadingAndShowError(error, 'Error while loading users!', ngxLoaderKey);
          this.loggerService.logError('this.userService.getUsersAndRolesForOrg-LoadUser: Error: ', errorMessage);
          return throwError(error);
        }));
  }

  applyFilter(newFilter: FilterData = {}) {
    this.filter = { ...this.filter, ...newFilter };
    this.isSelected = this.filter.roleId;
    this.dataSource.filter = Math.random().toString(); // hack to trigger filter refresh
  }

  /** To create user for an organization */
  addUser() {
    if (this.formGroup.valid) {
      const emailExist = this.userList.filter(userlist => userlist.email == this.formGroup.get('userEmail').value);
      if (emailExist.length == 1 || this.isEmailExist) {
        const loadingKey = 'Email already exist!';
        this.toastService.stopLoadingAndShowError(
          'Email already exist!', loadingKey);
      }
      else {
        const loadingKey = 'add-user';

        const orgUser: OrgUser = {
          ...this.formGroup.getRawValue(),
          orgId: this.isOrgLevel ? this.formGroup.get('siteId').value : this.paramOrgId,
        };

        this.ngxLoader.start(loadingKey);
        this.userService.createUserWithRole(orgUser,this.orgType, this.isGearIconClicked).subscribe(() => {
          this.ngxLoader.stop(loadingKey);
          this.toastService.openToast('New user created');
          this.reset();
          this.loadUsers();
        },
          catchError((error: HttpErrorResponse) => {
            let errorMessage: string;
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `${error.error.message}`;
            } else {
              // server-side error
              errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
            }
            this.toastService.stopLoadingAndShowError(error, 'Error while creating user!', loadingKey);
            this.loggerService.logError('createUserWithRole: Error: ', errorMessage);
            return throwError(error);
          })
        );
      }
    }
  }

  inactiveUsers() {
    const loadingKey = 'inactive-users';
    this.ngxLoader.start(loadingKey);

    const requests = this.selection.selected
      .map(({ userId, enabled, ...user }) =>
        this.userService.updateById(userId, { ...user, enabled: !enabled }),
      );

    forkJoin(requests).subscribe(() => {
      this.ngxLoader.stop(loadingKey);
      this.toastService.openToast('Users Inactivated');
      this.loadUsers();
    }, (response) => this.toastService.stopLoadingAndShowError(response,
      `Error while inactivate users!`, loadingKey,
    ));
  }


  editUser(user: OrgUsersAndRoles) {
    if (user) {
      this.editingUser = { ...user };
      this.editUserFormGroup.patchValue({
        lastName: user.lastName,
        firstName: user.firstName,
        roleId: user.roleId,
        siteId: user.organizationId
      });
      this.applyFilter(this.filter);
    } else {
      this.editingUser = null;
      // Clear the search input
      const searchInput = document.getElementById('UserManagementSearch') as HTMLInputElement;
      if (searchInput) {
        searchInput.value = '';
      }
      // Clear the role select
      this.selectedRoleId = '';
      this.isSelected = '';

      this.applyFilter({ text: '', organizationId: '', roleId: this.isSelected });
    }
  }

  cancelEditUser(){
    this.editingUser = null;
    this.applyFilter(this.filter);
  }

  changeRole() {
    const user: OrgUsersAndRoles = {
      ...this.editingUser,
      ...this.editUserFormGroup.getRawValue(),
    };

    const userRole: OrgUserRoles = {
      organizationId: this.paramParentOrgId,
      userId: user.userId,
      roles: [user.roleId]
    };

    const loadingKey = 'change-role';
    this.ngxLoader.start(loadingKey);

    this.userService.updateUserRole(userRole, this.orgType, this.isGearIconClicked).subscribe(() => {
      this.ngxLoader.stop(loadingKey);
      this.toastService.openToast('Role Updated Successfully!');
      this.loadUsers();
      this.editUser(null);
    },
      catchError((error: HttpErrorResponse) => {
        let errorMessage: string;
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
        }
        this.toastService.stopLoadingAndShowError(error, 'Error while updating role!', loadingKey);
        this.loggerService.logError('updateUserRole: Error: ', errorMessage);
        return throwError(error);
      })
    );
  }

  changeUserDetails() {
    const user: OrgUsersAndRoles = {
      ...this.editingUser,
      ...this.editUserFormGroup.getRawValue(),
      organizationId: this.editUserFormGroup.get('siteId').value
    };

    const userOrgRole: OrgUserRole = {
      orgId: user.organizationId,
      userId: user.userId,
      roleId: user.roleId,
      userFirstName : user.firstName,
      UserLastName : user.lastName
    };

    const loadingKey = 'change-role';
    this.ngxLoader.start(loadingKey);

    this.userService.updateUserDetails(userOrgRole).subscribe(() => {
      this.ngxLoader.stop(loadingKey);
      this.toastService.openToast('User details Updated Successfully!');
      this.loadUsers();
      this.editUser(null);
    },
      catchError((error: HttpErrorResponse) => {
        let errorMessage: string;
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
        }
        this.toastService.stopLoadingAndShowError(error, 'Error while updating role!', loadingKey);
        this.loggerService.logError('updateUserRole: Error: ', errorMessage);
        return throwError(error);
      })
    );
  }

  changeUserStatus({ userId, enabled, email, ...user }: OrgUsersAndRoles) {
    const loadingKey = 'change-user-status';
    this.ngxLoader.start(loadingKey);

    const request = enabled ? this.userService.deactivateUser(userId,this.orgType, this.isGearIconClicked) : this.userService.activateUser(userId,this.orgType, this.isGearIconClicked);
    request.subscribe(() => {
      this.ngxLoader.stop(loadingKey);
      const message = enabled ? 'User inactivated' : 'User reactivated';
      this.toastService.openToast(message);
      this.inActiveGroup = { groupName: 'Inactive Users', htmlId: 'UserManagementInactiveUsers', expanded: true };
      this.loadUsers();

      this.setInactivatedUser(enabled ? { userId, enabled: false, ...user } : null);

      //Disabling Active Report Subscriptions for the user
      if (enabled) {
        this.reportService.DisableAllSubscriptions(email).subscribe(() => {
        },
          catchError((error: HttpErrorResponse) => {
            let errorMessage: string;
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `${error.error.message}`;
            } else {
              // server-side error
              errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
            }
            this.toastService.stopLoadingAndShowError(error, 'Error while disabling active report subscriptions!', loadingKey);
            this.loggerService.logError('DisableAllSubscriptions: Error: ', errorMessage);
            return throwError(error);
          })
        );
      }
    },
      catchError((error: HttpErrorResponse) => {
        let errorMessage: string;
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
        }
        this.toastService.stopLoadingAndShowError(error, `Error while ${enabled ? 'inactivate' : 'reactivate'} user!`, loadingKey);
        this.loggerService.logError('changeUserStatus: Error: ', errorMessage);
        return throwError(error);
      })
    );
  }

  setInactivatedUser(user) {
    this.inactivatedUser = user;
    clearTimeout(this.inactivatedUserPanelTimeout);
    if (user) {
      this.inactivatedUserPanelTimeout = setTimeout(() => this.inactivatedUser = null, 5000);
    }
  }

  resendUserInvitation(userId: string) {
    const loadingKey = 'resend-invitation-user';
    this.ngxLoader.start(loadingKey);

    this.userService.resendInvitation(userId).subscribe(() => {
      this.ngxLoader.stop(loadingKey);
      this.toastService.openToast('Resend Invitation successful!');
    },
      catchError((error: HttpErrorResponse) => {
        let errorMessage: string;
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
        }
        this.toastService.stopLoadingAndShowError(error, `Error while resend invitation email!`, loadingKey);
        this.loggerService.logError('resendUserInvitation: Error: ', errorMessage);
        return throwError(error);
      })
    );
  }

  resetPassword(userId: string) {
    const loadingKey = 'reset-password';
    this.ngxLoader.start(loadingKey);

    this.userService.resetPassword(userId).subscribe(() => {
      this.ngxLoader.stop(loadingKey);
      this.toastService.openToast('Reset Password successful!');
    },
      catchError((error: HttpErrorResponse) => {
        let errorMessage: string;
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Status: ${error.status}\nMessage: ${error.message}`;
        }
        this.toastService.stopLoadingAndShowError(error, `Error while reset password!`, loadingKey);
        this.loggerService.logError('resetPassword: Error: ', errorMessage);
        return throwError(error);
      })
    );
  }

  reset() {
    this.formDirective.resetForm();
    this.formGroup.reset({
      userLastName: '',
      userFirstName: '',
      userEmail: '',
      role: '',
    });
  }

  showEditUser(user: OrgUsersAndRoles,id) {
    if (user) {
      this.editingUser = { ...user };
      this.editUserFormGroup.patchValue({
        lastName: user.lastName,
        firstName: user.firstName,
        roleId: user.roleId,
        name: user.lastName.toUpperCase() + ' - ' + user.firstName,
        email: user.email,
        siteId: user.organizationId
      });

      document.getElementById("matedit" + id).style.display = "block";
      document.getElementById("matcard" + id).style.display = "none";

    } else {
      this.editingUser = null;
    }
    this.applyFilter({ text: '', organizationId: '', roleId: this.isSelected });
  }

  hideEditUser(index) {
    document.getElementById("matedit" + index).style.display = "none";
    document.getElementById("matcard" + index).style.display = "flex";
    this.editingUser = null;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected ?
      this.selection.clear() :
      this.selectableRows.forEach(row => this.selection.select(row));
  }

  toggleRow(row) {
    if (row.userId && !(this.editingUser && this.editingUser.userId === row.userId)) {
      this.selection.toggle(row);
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: OrgUsersAndRoles): string {
    if (!row) {
      return `${this.isAllSelected ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.userId + 1}`;
  }

  /**
   * Custom filter for the table
   *
   * Note that this function don't use `this.dataSource.filter` for filter
   * because it's only support for text, we need filter by columns with custom logic
   * Change `this.dataSource.filter` to another value to trigger filter
   */
  private customFilterPredicate(data: TableRow): boolean {
    const isVisible = data.enabled ? this.activeGroup.expanded : this.inActiveGroup.expanded;

    const filterDataRow = () => {
      if (!isVisible) { return false; }
      const transformedFilter = this.filter.text.trim().toLowerCase();
      const searchStr = `${data.firstName} ${data.lastName}◬${data.email}`.toLowerCase();
      return searchStr.indexOf(transformedFilter) !== -1 &&
        (this.filter.roleId ? data.roleId === this.filter.roleId : true);
    };

    const filterTableGroup = () => this.isGroup(null, data);
    const filterNoDataRow = () => this.isNoDataRow(null, data) && isVisible;

    return filterTableGroup() || filterNoDataRow() || filterDataRow();
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.applyFilter();
  }

  isGroup(index, item): boolean {
    return 'groupName' in item;
  }

  isNoDataRow(index, item): boolean {
    return 'noDataText' in item;
  }

  isEditingRow(index, item): boolean {
    return this.editingUser && this.editingUser.userId === item.userId;
  }

  getId(item: TableRow, name: string) {
    return `UserManagement${item.enabled ? 'ActiveUsers' : 'InactiveUsers'}${name}${item.index}`;
  }

  getName(user: OrgUsersAndRoles) {
    return [
      user.lastName.toUpperCase(),
      user.firstName && user.lastName ? ', ' : '',
      user.firstName ? (user.firstName[0].toUpperCase() + user.firstName.substring(1)) : '',
    ].join('');
  }

  private setUsersToTableData(users: OrgUsersAndRoles[]) {
    this.selection.clear(); 

    const activeUsers = users.filter(({ enabled }) => enabled)
      .map((data, index) => ({ ...data, index })).sort((a, b) => { return compare(a?.lastName, b?.lastName, true); });
    const inActiveUsers = users.filter(({ enabled }) => !enabled)
      .map((data, index) => ({ ...data, index })).sort((a, b) => { return compare(a?.lastName, b?.lastName, true); });

    this.dataSource.data = [
      this.activeGroup,
      ...(activeUsers.length ? activeUsers :
        [{ noDataText: 'No active users', enabled: true } as NoDataRow]
      ),
      this.inActiveGroup,
      ...(inActiveUsers.length ? inActiveUsers :
        [{ noDataText: 'No inactive users', enabled: false } as NoDataRow]
      ),
    ];
    this.applyFilter({ text: '', organizationId: '', roleId: this.isSelected });
  }

  sortData(sort: Sort) {

    this.selection.clear();

    this.activeUsers = this.userList.filter(({ enabled }) => enabled)
      .map((data, index) => ({ ...data, index }));
    this.inActiveUsers = this.userList.filter(({ enabled }) => !enabled)
      .map((data, index) => ({ ...data, index }));

    const isAsc = sort.direction === 'asc';

    this.activeUsers = this.activeUsers.sort((a, b) => {
      switch (sort.active) {
        case 'lastName':
          return compare(a?.lastName, b?.lastName, isAsc);
        case 'email':
          return compare(a?.email, b?.email, isAsc);
        case 'roleName':
          return compare(a?.roleName, b?.roleName, isAsc);
        default:
          return 0;
      }
    });

    this.inActiveUsers = this.inActiveUsers.sort((a, b) => {
      switch (sort.active) {
        case 'lastName':
          return compare(a?.lastName, b?.lastName, isAsc);
        case 'email':
          return compare(a?.email, b?.email, isAsc);
        case 'roleName':
          return compare(a?.roleName, b?.roleName, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource.data = [
      this.activeGroup,
      ...(this.activeUsers.length ? this.activeUsers :
        [{ noDataText: 'No active users', enabled: true } as NoDataRow]
      ),
      this.inActiveGroup,
      ...(this.inActiveUsers.length ? this.inActiveUsers :
        [{ noDataText: 'No inactive users', enabled: false } as NoDataRow]
      ),
    ];

    this.applyFilter({ text: '', organizationId: '', roleId: this.isSelected });
  }
}

function compare(a: string, b: string, isAsc: boolean) {
  return (a?.toLocaleLowerCase() < b?.toLocaleLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
}
