import { DataService } from '../../../shared/services/data.service';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ERToolService extends DataService<any> {

  httpClient: HttpClient;

  constructor(http: HttpClient) {
    super(`${environment.apiUrl.erToolService}`, http);
    this.httpClient = http;
  }

  //just an example
  getSomeData(): Observable<any> {
    return this.http.get<any>(`${this.url}`);
  }
  
  getMdlSasToken(
    sourceId, fileExportType, format,
    url: string = this.url + "GetMDL"
  ): Observable<any> {
    return this.httpClient.get(url, {
      params: { sourceId: sourceId, fileExportType: fileExportType, format: format },
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    });
  }

  
 
}
