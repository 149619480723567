import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import * as constants from '../../../config/app-constants';
import { routePaths } from '../../../config/app-constants';
import { Role } from '../../models/auth/role';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-home',
  template: '',
})
export class HomeComponent implements OnInit {

  constructor(
    public auth: AuthService,
    private router: Router,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    const navigateByUrl = (url) => setTimeout(() => this.router.navigateByUrl(url), 100);

    this.auth.profile$.subscribe((profile) => {
      if (profile.hasAnyRoleOf(Role.admin)) {
        navigateByUrl(routePaths.CUSTOMER_MANAGEMENT);
      }
      else if (profile.hasAnyRoleOf(Role.implementationMgr)) {
        navigateByUrl(routePaths.IM_TOOL);
      }
      else if (profile.hasAnyRoleOf(Role.ptac) || profile.hasAnyRoleOf(Role.parataDev)) {
        navigateByUrl(routePaths.FLEET_MANAGEMENT);
      }
      else if (profile.hasAnyRoleOf(Role.rpo)) {
        navigateByUrl(routePaths.DRUG_MANAGEMENT);
      }
      else if (profile.hasAnyRoleOf(Role.ert)) {
        navigateByUrl(routePaths.ER_TOOL);
      }
      else if (profile.orgType == "External") {
        navigateByUrl(routePaths.REPORT_SUBSCRIPTION);
      }
      else {
        if (profile.userRoles.length) {
          this.toastService.openToast('Failed while redirecting!', constants.ToastPanelType.error);
        }
      }
    });
  }
}